import React from 'react';
import {Icon, iconKeys} from "../../../Icon/Icon";
import './SelectIcon.css'


export const SelectIcon = (props) => (
    <div className="selectIcon">
        <h1 className="selectIcon__header">
            Velg ikon
        </h1>
        <div
            className="selectIcon__iconContainer"
        >
            {iconKeys.map(iconType => (
                <div
                    key={iconType}
                    className="selectIcon__iconWrapper"
                    onClick={() => props.selectIcon(iconType)}
                >
                    <Icon
                        key={iconType}
                        id={iconType}
                        className="selectIcon__option"
                        type={iconType}
                    />
                </div>
            ))}
        </div>
    </div>
);