import React from 'react';
import {Fire} from "../Fire/Fire";
import {useNavigate} from "react-router-dom";

export const FireScreen = () => {
    const navigate = useNavigate();
    return (
        <div
            className="full screen centered"
            onClick={() => navigate(-1)}
        >
            <Fire/>
        </div>
    );
}