import React from 'react';

export const NavButtonBackground = ({
                                        notchSide,
                                        width,
                                        onClick
                                    }) => {

    let content;
    if (notchSide) {
        content = (
            <path
                d={`M 33.955535,0 H ${width} V 80 H 0 V 33.58004 C 20.504649,33.58004 33.955535,19.300156 33.955535,0 Z`}
                onClick={onClick}
            />
        )
    } else {
        content = <rect x="0" y="0" width={width} height="80" onClick={onClick}/>
    }

    return (
        <svg
            height="80"
            width={width}
            // viewBox="0 0 60 80"
            viewBox={`.1 0 ${width} 80`}
            xmlns="http://www.w3.org/2000/svg"
            className={`navButtonBg ${notchSide === 'right' ? 'flip' : ''}`}
        >
            {content}
            {/*<path*/}
            {/*    d={*/}
            {/*        notchSide === 'right' ?*/}
            {/*            "M 26.044465,0 H 0 V 80 H 60 V 33.58004 C 39.495351,33.58004 26.044465,19.300156 26.044465,0 Z" :*/}
            {/*            "M 33.955535,0 H 60 V 80 H 0 V 33.58004 C 20.504649,33.58004 33.955535,19.300156 33.955535,0 Z"*/}
            {/*    }*/}
            {/*/>*/}
        </svg>
    );
}