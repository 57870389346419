import React from 'react';
import './CentralButton.css'

export const CentralButton = ({className, onClick, children}) => (
    <div className="centralButtonWrapper">
        <div
            className={`centralButton ${className}`}
            onClick={onClick}
        >
            {children}
        </div>
    </div>
);