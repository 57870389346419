import React from 'react';
import {useRecoilState, useSetRecoilState} from "recoil";
import {keepOpenOptionsState, locatingState, resultsState, searchingState, searchState, walkSpeedState} from "./States";
import useEffectPartialDeps from "../utils/UseEffectPartialDeps";
import {getConnections} from "../utils/api";
import {getGpsLocation, getOptionKey} from "../utils/utils";
import moment from "moment";
import {useAnalyticsEventTracker} from "./ga/useAnalyticsEventTracker";

export const SearchController = () => {
    const [searching, setSearching] = useRecoilState(searchingState);
    const [search] = useRecoilState(searchState);
    const [keepOpenOptions] = useRecoilState(keepOpenOptionsState);
    const setLocating = useSetRecoilState(locatingState);
    const setResults = useSetRecoilState(resultsState);
    const [walkSpeed] = useRecoilState(walkSpeedState);

    const gaEventTracker = useAnalyticsEventTracker('Journey search');

    const setAdjustedResults = results => {
        setResults(oldState => {
            const now = moment();
            let oldOpenResults = oldState?.filter(o => o.expanded);
            const taggedResults = results.map(result => {
                const key = getOptionKey(result);
                const oldOption = (oldOpenResults && oldOpenResults.find(old => old.key === key)) || {};
                return {
                    ...result,
                    timeStamp: now,
                    key,
                    expanded: oldOption.expanded,
                    countDown: oldOption.countDown
                };
            });
            if (keepOpenOptions) {
                oldOpenResults =
                    oldOpenResults && oldOpenResults.length > 0 ?
                        oldOpenResults
                            .filter(option => !taggedResults.find(o => o.key === option.key))
                            .map(option => ({
                                    ...option,
                                    timeStamp: moment(option.timeStamp)
                                })
                            ) :
                        [];
                return [...oldOpenResults, ...taggedResults];
            }
            else return taggedResults;
        })
        setSearching(false);
    };

    useEffectPartialDeps(() => {
        if (!search) return;
        if (!navigator.onLine) {
            alert('Ser ikke ut som du har noe internetting å søke med.');
            return;
        }
        const [from, to] = search;
        if (searching || !to || !from || (!to.code && !to.gps) || (!from.code && !from.gps)) return;
        const doit = (fromArg, toArg) => {
            setLocating(false);
            setSearching(true);

            const f = fromArg || from;
            const t = toArg || to;

            getConnections(f.code, t.code, 2, 100, walkSpeed)
                .then(setAdjustedResults);
        }
        if (from.gps) {
            setLocating(true);
            getGpsLocation().then((code) => {
                setSearching(false);
                doit(code);
                gaEventTracker('from gps')
                setSearching(true);
            });
        } else if (to.gps) {
            setLocating(true)
            getGpsLocation().then((code) => {
                setSearching(false);
                doit(null, code);
                gaEventTracker('to gps')
                setSearching(true);
            });
        }
        else {
            doit();
            gaEventTracker('no gps')
        }
    },[search])

    return (
        <></>
    );
}