import React, {useState} from 'react';
import './EditNode.css'
import {useRecoilState} from "recoil";
import {editingNodeState, nodesState} from "../../States";
import {uuidv4} from "../../../utils/utils";
import {TextInput} from "../../Input/TextInput";
import LocationSearch from "./LocationSearch/LocationSearch";
import {SelectIcon} from "./SelectIcon/SelectIcon";
import {Icon} from "../../Icon/Icon";
import {ToggleSwitch} from "../../Input/ToggleSwitch";

export const EditNode = () => {
    const [editingNode, setEditingNode] = useRecoilState(editingNodeState);
    const [locations, setLocations] = useRecoilState(nodesState);
    const [selectIcon, setSelectIcon] = useState(false);
    const [searchingLocation, setSearchingLocation] = useState(false);

    const renderLocationSelect = () => {
        return (
            <div
                className={'locationSelect'}
                onClick={() => setSearchingLocation(true)}
            >
                <p className={'unselectable'}>Sted</p>
                <button className={'locationSelectButton'}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 -2 30 30"
                        height="24"
                        width="24"
                    >
                        <mask id="mapMask">
                            <rect x="-10" y="-10" width="330" height="330" fill="white"/>
                            <circle
                                cx="13.5"
                                cy="5"
                                r="5"
                                fill="#777"
                            />
                        </mask>
                        <g
                            opacity={.7}
                            transform="translate(-2, 1) scale(1.2)"
                            mask="url(#mapMask)"
                        >
                            <path
                                d="M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM10 5.47l4 1.4v11.66l-4-1.4V5.47zm-5 .99l3-1.01v11.7l-3 1.16V6.46zm14 11.08l-3 1.01V6.86l3-1.16v11.84z"
                            />
                        </g>
                        <g
                            transform="translate(5, -2)"
                        >
                            <path
                                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
                            />
                        </g>
                    </svg>
                </button>
            </div>
        )
    }

    const chooseLocationFromSearch = (location) => {
        console.log('chooseLocationFromSearch: ', location);
        setEditingNode(oldNode => ({
            ...oldNode,
            code: location.properties.id.substr(0, 3) === 'NSR' ?
                `place: "${location.properties.id}" name: "${location.properties.label}"` :
                `coordinates: {
				longitude: ${location.geometry.coordinates[0]}
				latitude: ${location.geometry.coordinates[1]}
        }`,
            name: location.properties.label,
            coordinate: location.geometry.coordinates,
            locationFromMap: false,
            locationFromSearch: true,
        }));
        if (!editingNode.label && location.properties && location.properties.name) {
            setEditingNode(oldNode => ({
                ...oldNode,
                label: location.properties.name
            }));
        }
        setSearchingLocation(false);
    };

    const chooseLocationFromMap = ([lng, lat]) => {
        setEditingNode({
            ...editingNode,
            code: `coordinates: {
				longitude: ${lng}
				latitude: ${lat}
        }`,
            name: `Map coordinate`,
            coordinate: [
                lng,
                lat,
            ],
            locationFromMap: true,
            locationFromSearch: false,
        });
        setSearchingLocation(false);
    };


    const content = selectIcon ? (
        <SelectIcon
            selectIcon={iconType => {
                setEditingNode({
                    ...editingNode,
                    iconType
                })
                setSelectIcon(false);
            }}
        />
    ) : searchingLocation ? (
        <LocationSearch
            chooseSearchLocation={chooseLocationFromSearch}
            chooseMapLocation={chooseLocationFromMap}
            cancel={() => setSearchingLocation(false)}
            prevLoc={editingNode}
        />
    ) : (
        <div className="editNodeContent">
            <TextInput
                className={'nodeLabelField'}
                label={'Navn'}
                value={editingNode.label}
                onChange={label => setEditingNode({...editingNode, label})}
            />
            {renderLocationSelect()}
            <div
                className={`editLocation__icon ${editingNode.small ? 'small' : ''}`}
                onClick={() => setSelectIcon(true)}
            >
                <p className={'unselectable'}>Ikon</p>
                <div className="editLocation__iconWrapper">
                    <Icon type={editingNode.iconType}/>
                </div>
            </div>
            <ToggleSwitch
                label={"Lite ikon"}
                active={editingNode.small}
                onChange={small => setEditingNode({...editingNode, small})}
                transitionTime={200}
            />
            <ToggleSwitch
                label={"Vis navn"}
                active={editingNode.showLabel}
                onChange={showLabel => setEditingNode({...editingNode, showLabel})}
                transitionTime={200}
            />
            <br/>
            <div className="finalButtons">
                <button
                    className="danger"
                    onClick={() => setEditingNode(null)}
                >
                    Avbryt
                </button>
                <button
                    className={
                        (editingNode.code && editingNode.label.length > 0) ? 'safe' : 'disabled'
                    }
                    onClick={() => {
                        if (!editingNode.code) {
                            alert('Du må velge sted. Trykk på knappen under "Sted"')
                            return;
                        }
                        if (editingNode.label.length === 0) {
                            alert('Navn mangler.')
                            return;
                        }
                        const id = editingNode.id || uuidv4();
                        setLocations({
                            ...locations,
                            [id]: {
                                ...editingNode,
                                id
                            }
                        })
                        setEditingNode(null);
                    }}
                >
                    Ferdig
                </button>
            </div>
        </div>
    )

    return (
        <div className="editNode full screen">
            {
                (searchingLocation || selectIcon) && (
                    <button
                        className={`closeFullscreenButton ${searchingLocation ? 'black' : ''}`}
                        onClick={() => {
                            searchingLocation ?
                                setSearchingLocation(false) :
                                setSelectIcon(false);
                        }}
                    >
                        <Icon type={'close'}/>
                    </button>
                )
            }
            {content}
        </div>
    );
}