import React, {useState} from 'react';
import useEffectOnce from "../utils/UseEffectOnce";
import {Fire} from "./Fire/Fire";

const frameDuration = 80;
const frameCount = 11;
let interval

export const AnimationStepper = () => {
    const [frame, setFrame] = useState(5);


    useEffectOnce(() => {
        document.addEventListener('keydown', onKeyPress);
        pausePlay();
        return () => {
            if (interval) {
                clearInterval(interval);
                interval = null;
            }
            document.removeEventListener('keydown', onKeyPress)
        }
    })

    const onKeyPress = (e) => {
        if (['ArrowLeft', 'a'].includes(e.key)) {
            tock();
            return;
        }if (['ArrowRight', 'd'].includes(e.key)) {
            tick();
            return;
        }if (['Space'].includes(e.code)) {
            pausePlay();
            return;
        }
    }

    const pausePlay = () => {
        if (interval) {
            clearInterval(interval);
            interval = null;
        } else {
            interval = setInterval(tick, frameDuration);
        }
    }

    const tock = () => setFrame(of => {
        let nf = of - 1;
        if (nf < 0) return frameCount - 1;
        return nf;
    })

    const tick = () => setFrame(of => {
        let nf = of + 1;
        if (nf < frameCount) return nf;
        return 0;
    })

    return (
        <>
            <h1>{frame + 1}</h1>
            <Fire frame={frame}/>
        </>
    );
}