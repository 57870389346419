import React from 'react';
import './CountdownScreen.css';
import {Monks} from "../../../Monks/Monks";
import {Loader} from "../../../Loader/Loader";
import {Icon} from "../../../Icon/Icon";

export const CountdownScreen = props => {
    return (
        <div
            className={`countdownScreen full screen ${props.searching ? 'searching' : ''}`}
            style={{backgroundColor: props.etaColor}}
        >
            {props.searching && (<Loader overlay/>)}
            <Monks time={props.startTime}/>
            <div className={"countdownContent"}>
                <div className="countdownScreen__section">
                <span
                    className={'countdown'}
                    onClick={props.search}
                >
                    {props.countdownString}
                </span>
                </div>
                    <button
                        className={'countdownScreen__button countdownScreen__backButton'}
                        onClick={props.close}
                    >
                        <Icon type={'back'}/>
                    </button>
            </div>
        </div>
    )
};