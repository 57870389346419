import React, {Fragment} from "react";
import {formatTime, getDifferenceString, getLegPath, getStopsPassed} from "../../../utils/utils";
import moment from "moment";
import {StepElement} from "./StepElement";
import {CountdownScreen} from "./CountdownScreen/CountdownScreen";
import {Icon} from "../../Icon/Icon";
import {useRecoilState} from "recoil";
import {fuseState} from "../../States";

const Option = (props) => {
    const [fuse] = useRecoilState(fuseState);

    let option = props.option;
    let busNumbers = option.legs.map(leg => {
        return leg.mode === 'foot' ? 'Gå' : leg.line ? leg.line.publicCode : '-';
    });
    let startTime = moment(option.startTime);
    let endTime = moment(startTime + (option.duration * 1000));

    const durationSeconds = startTime.diff(props.option.timeStamp, 'seconds')
    let eta = startTime.diff(props.now, 'seconds');
    let age = props.now.diff(props.option.timeStamp, 'seconds');

    let ageFactor = (age < durationSeconds / 2 ) ? 0 : age / durationSeconds;

    if (ageFactor > 1 || ageFactor < 0) ageFactor = 1;
    const etaColor = `rgb(${200 * ageFactor},0,0)`;

    const headerTimeStyle = {
        color: etaColor
    }

    const countdownString = getDifferenceString(props.now, startTime)

    const getDetails = (option, startTime, endTime, etaColor) => {
        let stepObjects = getSteps(option, startTime, endTime);

        return (
            <div className="option__details optionDetails">
                <div className="optionDetails__time">
                    <div className="optionDetails__duration optionDetails__element">
                        Reisetid: {getDifferenceString(startTime, endTime)}
                    </div>
                    <div
                        className="optionDetails__eta"
                        onClick={(e) => {
                            e.stopPropagation();
                            props.toggleCountdown();
                        }}
                    >
                        <span
                            className={'optionDetails__etaTime'}
                            style={{
                                backgroundColor: etaColor
                            }}
                        >
                            {getDifferenceString(props.now, startTime)}
                            </span>
                    </div>
                </div>
                <div className="optionDetails__step s optionSteps">
                    {stepObjects.map((stepObject, index) =>
                        <StepElement
                            key={index}
                            stepObject={stepObject}
                            mapStyle={props.mapStyle}
                            locator={props.locator}
                            searchFromHere={() =>
                                stepObject.searchStartLocation &&
                                props.searchFrom([stepObject.searchStartLocation], stepObject.searchStartLabel)
                            }
                        />
                    )}
                </div>
            </div>
        )
    };

    const getSteps = (option, startTimeArg, endTimeArg) => {
        let needsToLabel = false;
        let needsEndTime = false;
        const getPlaceLabel = (place) =>
            place.quay?.publicCode ? `${place.name} ${place.quay?.publicCode}` : place.name;

        let stepObjects = [];
        let prevObject;
        const lastIndex = option.legs.length - 1;
        option.legs.forEach((leg, index) => {
            let searchStartLocation, searchStartLabel;
            if (index > 0 &&
                leg.fromPlace &&
                leg.fromPlace.quay) {

                searchStartLocation = `place: "${leg.fromPlace.quay.id}",
                                       name: "${leg.fromPlace.quay.name}"`;
                if (leg.fromPlace)
                    searchStartLabel = leg.fromPlace.name;
            }

            // get leg label
            let mode = leg.mode === 'foot' ? 'Gå' : leg.line ? leg.line.publicCode : '-';

            // get leg starting location
            let fromLabel = leg.fromPlace && leg.fromPlace.name !== 'Origin' ? getPlaceLabel(leg.fromPlace) :
                index === 0 ? props.fromLabel : prevObject.toLabel;

            // set the end location of the previous leg to this leg's start location if needed
            if (needsToLabel) {
                prevObject.toLabel = fromLabel;
                needsToLabel = false;
            }

            // set leg end location
            let toLabel = 'error';
            if (index === lastIndex) {
                toLabel = props.toLabel;
            } else if (leg.toPlace) {
                toLabel = getPlaceLabel(leg.toPlace);
            } else {
                needsToLabel = true;
            }

            // set leg start time
            let startTime = leg.fromEstimatedCall ? moment(leg.fromEstimatedCall.expectedDepartureTime) :
                index === 0 ? startTimeArg : prevObject.endTime;

            // set the end time of the previous leg to this leg's start time if needed
            if (needsEndTime) {
                prevObject.endTime = startTime;
                needsEndTime = false;
            }

            // set leg end time
            let endTime = null;
            if (leg.toEstimatedCall) {
                endTime = moment(leg.toEstimatedCall.expectedDepartureTime);
            } else {
                needsEndTime = true;
            }

            const path = getLegPath(leg);
            const stopsPassed = getStopsPassed(leg);

            prevObject = {
                mode,
                fromLabel,
                toLabel,
                startTime,
                endTime,
                path,
                searchStartLocation,
                searchStartLabel,
                stopsPassed
            };
            stepObjects.push(prevObject)
        });

        if (needsToLabel) {
            prevObject.toLabel = props.toLabel;
        }

        if (needsEndTime) {
            prevObject.endTime = endTimeArg;
        }

        return stepObjects;
    };

    const renderFuse = () => {
        const duration = startTime.diff(props.option.timeStamp)
        const elapsed = props.now.diff(props.option.timeStamp);
        const percentage = (elapsed/duration) * 100;
        return (
            <div className="option__fuse">
                <div style={{right: `${100 - (eta > 0 ? percentage : 100)}%`}}/>
            </div>
        )
    }

    return option.countDown ? (
        <CountdownScreen
            startTime={startTime}
            countdownString={countdownString}
            close={e => {
                e.stopPropagation();
                props.toggleCountdown();
            }}
            searching={props.searching}
            countDownBg={props.countDownBg}
            etaColor={etaColor}
            search={props.search}
        />
    ) : (
        <div
            className="option"
            onClick={props.toggleExpanded}
        >
            <div className="option__header">
                <div className="option__busNumberContainer">
                    <div className="option__busNumbers">
                        {busNumbers.map((busNumber, i) => {
                            return (
                                <Fragment
                                    key={`blapp${i}`}
                                >
                                    <div
                                        className={`${busNumber === 'Gå' ? 'option__busNumberElement--foot' : ''}`}>
                                        {
                                            busNumber === 'Gå' ?
                                                <Icon
                                                    color={'#ffffff'}
                                                    type={'boot'}
                                                /> :
                                                busNumber
                                        }
                                    </div>
                                    {busNumbers.length - 2 < i ? '' :
                                        <div className="option__busNumberElement--dash">{' - '}</div>
                                    }
                                </Fragment>
                            );
                        })
                        }
                    </div>
                </div>
                <div className="option__time option__startTime" style={headerTimeStyle}>
                    {props.countdownOnOptions && !option.expanded ? countdownString : formatTime(startTime)}
                </div>
                <div className="option__time option__endTime" style={headerTimeStyle}>
                    {formatTime(endTime)}
                </div>
            </div>
            {fuse && renderFuse()}
            {
                option.expanded ? getDetails(option, startTime, endTime, etaColor) : ''
            }
        </div>
    )
}


export default Option;