import React, {useState} from 'react';
import Map from "../MapView/Map";
import {formatTime} from "../../../utils/utils";


export const StepElement = props => {
    const [expanded, setExpanded] = useState(false);
    return (
        <div
            className="stepElementWrapper"
            onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
            }}
        >
            <div
                className="optionSteps__step optionStep"
            >
                    <div
                        className="optionStep__mode"
                        onClick={(e) => {
                            if (props.searchFromHere){
                                e.stopPropagation();
                                props.searchFromHere()
                            }
                        }}
                    >
                        {props.stepObject.mode}
                    </div>
                <div className="optionStep__spaceTimeWrapper">
                    <div className="optionStep__spaceTime optionStep__spaceTime--from">
                        <span className="optionStep__placeLabel">{props.stepObject.fromLabel}</span>
                        <span className="optionStep__timeLabel">{formatTime(props.stepObject.startTime)}</span>
                    </div>
                    <div className="optionStep__spaceTime">
                        <span className="optionStep__placeLabel">{props.stepObject.toLabel}</span>
                        <span className="optionStep__timeLabel">{formatTime(props.stepObject.endTime)}</span>
                    </div>
                </div>
            </div>
            {
                expanded &&
                <div className="pathMapDiv">
                    <Map
                        stopsPassed={props.stepObject.stopsPassed}
                        path={props.stepObject.path}
                        mapStyle={props.mapStyle}
                        locator={props.locator}
                    />
                </div>
            }
        </div>
    );
};
