import React from 'react';
import './DeleteNode.css'
import {useRecoilState} from "recoil";
import {deletingNodeState, nodesState} from "../../../States";
import {Icon} from "../../../Icon/Icon";
export const DeleteNode = () => {
    const [deletingNode, setDeletingNode] = useRecoilState(deletingNodeState);
    const [locations, setLocations] = useRecoilState(nodesState);

    return (
        <div className="deleteNode">
            <h1>Slett lokasjon?</h1>

            <Icon
                type={deletingNode.iconType}
            />
            <h2>{deletingNode.label}</h2>
            <div className="finalButtons">
                <button
                    className="danger"
                    onClick={() => {
                        const newLocations = {...locations};
                        delete newLocations[deletingNode.id];
                        setLocations(newLocations);
                        setDeletingNode(null);
                    }}
                >
                    Slett
                </button>
                <button
                    className={'safe'}
                    onClick={() => setDeletingNode(null)}
                >
                    Avbryt
                </button>
            </div>
        </div>
    );
}