
class TouchController {
    static constructor(){
        this.startX = null;
        this.startY = null;
        this.x = null;
        this.prevX = null;
        this.y = null;
        this.prevY = null;

    };


    onTouchStart = e => {
        this.x = this.prevX = this.startX = e.touches[0].clientX;
        this.y = this.prevY = this.startY = e.touches[0].clientY;
    };

    onTouchMove = e => {
        this.prevX = this.x;
        this.x = e.touches[0].clientX;

        this.prevY = this.y;
        this.y = e.touches[0].clientY;
    };

    // onTouchEnd = () => {};

    // getXChange = (total) => {
    //     return this.x - (total ? this.startX : this.prevX);
    // };

    // getYChange = (total) => {
    //     return this.y - (total ? this.startY : this.prevY);
    // };

    // getChange = (total) => {
    //     let xChange = this.getXChange(total);
    //     let yChange = this.getYChange(total);
    //     return Math.sqrt(Math.pow(this.xChange, 2) + Math.pow(this.yChange, 2));
    // };

    getPosition = () => [this.y, this.x];

    // getDistance = (p1, p2) => {
    //     let x = Math.abs(p1[0] - p2[0]);
    //     let y = Math.abs(p1[1] - p2[1]);
    //     Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
    // }
}

export default TouchController;