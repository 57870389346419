import React, {useState} from 'react';
import './Fire.css'
import useEffectOnce from "../../utils/UseEffectOnce";

const fire1 = [
    "M 17.849809,16.535821 C 17.934739,15.269759 16.820124,11.728945 14.805035,10.117432 12.417181,8.2078103 10.80678,8.7365114 11.497924,1.9359273 7.0089275,3.6915944 5.9394239,5.8050869 4.7572543,9.7536333 4.1080867,11.921908 4.6145331,14.564388 4.8305176,16.490747 c 0.2117588,1.888671 1.2674179,5.36687 6.6674064,5.339107 6.031032,-0.01731 6.251849,-3.802855 6.351885,-5.294033 z",
    "m 17.849809,16.535821 c 0.02118,-2.25411 -0.83845,-4.902494 -2.853539,-6.514007 C 12.608416,8.1121928 9.6912422,8.3540413 9.5537009,1.9359273 5.0647044,3.6915944 4.6476856,8.0280215 4.7572543,9.7536333 c 0.1967646,3.0988717 0.3353664,4.9765237 0.2963709,6.7371137 -0.042084,1.900039 1.0443103,5.36687 6.4442988,5.339107 6.031032,-0.01731 6.337839,-3.799569 6.351885,-5.294033 z",
    "m 17.849809,16.535821 c 0.02118,-2.25411 -1.54971,-5.74384 -3.522862,-7.4064373 C 12.098456,7.251635 10.838653,7.6847186 8.0875654,1.9359273 4.683824,5.3396687 4.61731,6.9481789 5.1715969,9.4986532 c 0.6721869,3.0929748 0.685964,4.5940538 -0.1179717,6.9920938 -0.604095,1.80194 1.0443103,5.36687 6.4442988,5.339107 6.031032,-0.01731 6.337839,-3.799569 6.351885,-5.294033 z",
    "M 17.849809,16.535821 C 17.870989,14.281711 16.795549,11.094754 15.12376,9.1293837 13.309611,6.9966549 11.954191,7.1747584 7.7050953,1.9359273 4.3013539,5.3396687 5.6691028,6.9481789 6.2233897,9.4986532 6.8955766,12.591628 5.9664426,13.767926 5.0536252,16.490747 c -0.604095,1.80194 1.0443103,5.36687 6.4442988,5.339107 6.031032,-0.01731 6.337839,-3.799569 6.351885,-5.294033 z",
    "m 17.849809,16.535821 c 0.02118,-2.25411 -0.24501,-5.620754 -1.738001,-7.7251624 C 14.010806,5.8492445 12.655386,5.0711727 8.1831829,1.9359273 7.6160949,6.8376767 7.995796,7.6175016 7.6576526,10.008613 c -0.443199,3.133992 -1.56372,2.675648 -2.6040274,6.482134 -0.5010307,1.833272 1.0443103,5.36687 6.4442988,5.339107 6.031032,-0.01731 6.337839,-3.799569 6.351885,-5.294033 z",
    "M 17.849809,16.535821 C 17.297284,14.632309 17.90135,10.784483 16.717386,8.4919335 15.253834,5.6580094 14.790844,4.8480651 9.8086809,1.9359273 9.2415929,6.8376767 9.0836243,8.1062414 8.1357402,10.327338 c -0.698179,1.635984 -2.3605327,2.038198 -3.082115,6.163409 -0.3274645,1.87208 1.0443103,5.36687 6.4442988,5.339107 6.031032,-0.01731 6.7685,-3.858745 6.351885,-5.294033 z",
    "M 17.849809,16.535821 C 16.336868,12.923128 17.516398,10.893326 17.004239,8.3644435 16.656224,6.6460572 16.480087,4.40185 11.912267,1.9359273 11.345179,6.8376767 9.752947,7.9468789 8.5182103,10.072358 c -0.8934839,1.538045 -3.0298554,1.910708 -3.4645851,6.418389 -0.1824419,1.891727 1.0443103,5.36687 6.4442988,5.339107 6.031032,-0.01731 6.897378,-3.991472 6.351885,-5.294033 z",
    "M 17.849809,16.535821 C 16.336868,12.923128 17.06676,10.943909 17.004239,8.3644435 16.974949,7.1560174 17.085665,4.338105 13.665255,1.9359273 13.098167,6.8376767 10.333889,7.6581064 8.7413179,9.5305253 7.975324,10.43112 5.4883549,11.983066 5.0536252,16.490747 c -0.1824419,1.891727 1.0443103,5.36687 6.4442988,5.339107 6.031032,-0.01731 6.897378,-3.991472 6.351885,-5.294033 z",
    "M 17.849809,16.535821 C 15.73129,12.731893 15.593852,11.249064 16.207426,8.460061 16.464829,7.2900361 16.38447,3.6050373 14.589558,1.9359273 12.237609,5.7540114 9.6326938,7.2756363 8.0401227,9.1480552 7.2741288,10.04865 5.3608649,11.536851 5.0536252,16.490747 c -0.1176429,1.89686 1.0443103,5.36687 6.4442988,5.339107 6.031032,-0.01731 7.038991,-4.060293 6.351885,-5.294033 z",
    "M 17.849809,16.535821 C 17.313041,13.649789 15.483275,12.895872 14.117042,10.3496 13.293131,8.8140636 13.686632,5.3712274 14.505266,1.9810018 9.9222417,3.8548628 6.5793395,7.1781423 5.7835812,9.3692863 5.3799981,10.480564 4.9551941,11.356553 5.0536252,16.490747 c 0.036429,1.900155 1.0443103,5.36687 6.4442988,5.339107 6.031032,-0.01731 6.610104,-3.905669 6.351885,-5.294033 z",
    "M 17.849809,16.535821 C 16.591848,13.018745 15.915285,11.227284 13.80152,9.2227361 12.725861,8.2026555 11.9738,6.2952554 13.378403,1.9359273 8.7953783,3.8097883 7.210383,6.0963534 6.4146247,8.2874974 6.0110416,9.3987752 5.3608649,11.536851 5.0536252,16.490747 c -0.1176429,1.89686 1.0443103,5.36687 6.4442988,5.339107 6.031032,-0.01731 6.827474,-3.964354 6.351885,-5.294033 z",

]
const fire2 = [
    "M 21.325967,15.911263 C 19.830874,9.9178189 16.517392,8.5828087 15.249525,6.3798201 12.093202,0.89553102 18.161885,0.89294075 14.172389,-5.3830946 9.9776573,0.84017798 6.6226507,-0.57822756 3.2455273,5.5478099 1.7203654,8.3144247 1.7353322,11.012748 1.7668977,15.845521 c 0.018106,2.77201 1.90406,7.827732 10.0165413,7.787238 9.060506,-0.02526 10.070197,-5.606207 9.542528,-7.721496 z",
    "M 21.325967,15.911263 C 19.830874,9.9178189 17.006379,8.2166953 15.249525,6.3798201 10.650817,1.5716491 16.899798,1.4789097 14.713283,-5.3830946 10.338254,0.65987984 6.1268308,-1.5247928 2.7497074,4.6012446 1.2245455,7.3678594 1.7353322,11.012748 1.7668977,15.845521 c 0.018106,2.77201 1.90406,7.827732 10.0165413,7.787238 9.060506,-0.02526 10.070197,-5.606207 9.542528,-7.721496 z",
    "M 21.325967,15.911263 C 19.921023,8.5205083 16.871155,7.4504282 15.114301,5.613553 10.515593,0.80538199 14.510847,0.66756805 14.24,-5.3830946 7.9943781,-1.7065333 5.7662345,-1.5698673 2.3891111,4.5561701 0.86394921,7.3227849 2.141003,10.877524 1.7668977,15.845521 c -0.2081559,2.764243 1.90406,7.827732 10.0165413,7.787238 9.060506,-0.02526 9.949665,-5.579739 9.542528,-7.721496 z",
    "M 21.325967,15.911263 C 19.921023,8.5205083 17.495263,7.0329026 15.519972,5.4332549 10.695891,1.5265746 11.355629,-2.3073513 12.707466,-5.3830946 6.1463221,-2.4277259 4.9548929,-0.89374926 2.7497074,3.9251266 1.4351372,6.7977877 3.4693978,10.096804 1.8570468,14.898956 c -1.03257315,3.075368 1.8139109,8.774297 9.9263922,8.733803 9.060506,-0.02526 9.949665,-5.579739 9.542528,-7.721496 z",
    "M 21.325967,15.911263 C 20.777439,9.421999 18.650611,6.3209567 16.646835,4.7571369 11.236785,0.53493481 9.4624985,-2.3073513 10.814335,-5.3830946 4.2531916,-2.4277259 5.135191,3.7489279 4.5977634,7.0803441 4.0946295,10.199179 2.3425344,10.232028 1.8570468,14.898956 c -0.335662,3.226674 1.8139109,8.774297 9.9263922,8.733803 9.060506,-0.02526 9.726155,-5.549132 9.542528,-7.721496 z",
    "M 21.325967,15.911263 C 20.777439,9.421999 19.146431,5.5096151 17.142655,3.9457953 11.732605,-0.27640684 8.8765295,-1.7213823 8.9212045,-5.3830946 4.0278189,-2.0671296 7.073396,2.9375863 6.5359684,6.2690025 6.0328345,9.3878374 2.3425344,10.232028 1.8570468,14.898956 c -0.335662,3.226674 1.8139109,8.774297 9.9263922,8.733803 9.060506,-0.02526 9.726155,-5.549132 9.542528,-7.721496 z",
    "M 21.325967,15.911263 C 19.650576,10.774235 20.63389,5.4645406 17.683549,2.9541555 12.456914,-1.4930815 10.003393,-1.8115314 7.8394156,-5.3830946 5.3349804,-2.4728004 9.1918992,3.5235553 6.9867138,6.4943752 5.1037731,9.0310678 2.3425344,10.232028 1.8570468,14.898956 c -0.335662,3.226674 1.8139109,8.774297 9.9263922,8.733803 9.060506,-0.02526 10.218507,-5.648832 9.542528,-7.721496 z",
    "M 21.325967,15.911263 C 19.199831,10.639011 19.659674,5.9735668 18.49489,3.3147518 17.110379,0.15437312 12.212045,-2.4876494 7.8394156,-5.3830946 7.2281109,-1.6614588 10.544135,3.162959 6.9867138,6.4943752 4.6808055,8.6537876 2.3425344,10.232028 1.8570468,14.898956 c -0.335662,3.226674 1.8139109,8.774297 9.9263922,8.733803 9.060506,-0.02526 10.357896,-5.699601 9.542528,-7.721496 z",
    "M 21.325967,15.911263 C 19.515353,10.639011 18.889324,8.0345575 18.652651,5.5008668 18.050226,-0.9483508 13.834728,-3.4792892 8.7409063,-5.3830946 9.9325831,-1.6614588 10.61241,1.8571244 6.5140946,5.2786896 4.0889978,7.3033329 2.3425344,10.232028 1.8570468,14.898956 c -0.335662,3.226674 1.8139109,8.774297 9.9263922,8.733803 9.060506,-0.02526 10.250636,-5.659587 9.542528,-7.721496 z",
    "M 21.325967,15.911263 C 20.506993,10.954533 18.942409,9.5322524 17.953996,7.7771309 15.75789,3.8775161 19.829641,-2.1721277 10.543888,-5.3830946 11.735565,-1.6614588 10.273687,0.45848688 6.1753721,3.8800521 3.7502753,5.9046954 2.3425344,10.232028 1.8570468,14.898956 c -0.335662,3.226674 1.8139109,8.774297 9.9263922,8.733803 9.060506,-0.02526 9.897918,-5.570547 9.542528,-7.721496 z",
    "M 21.325967,15.911263 C 20.506993,10.954533 17.798202,8.8046531 16.809789,7.0495316 14.613683,3.1499168 19.271403,-1.4709325 12.572242,-5.3830946 13.763919,-1.6614588 10.048314,0.00774152 5.9499994,3.4293067 3.5249026,5.45395 2.3425344,10.232028 1.8570468,14.898956 c -0.335662,3.226674 1.8139109,8.774297 9.9263922,8.733803 9.060506,-0.02526 9.897918,-5.570547 9.542528,-7.721496 z",
]

// export const Fire = ({frame}) => {

let i;
export const Fire = () => {
    const [frame, setFrame] = useState(0);

    useEffectOnce(() => {
        i = setInterval(() => setFrame(of => {
            let nf = of + 1;
            if (nf < 11) return nf;
            return 0;
        }), 150);
        return () => clearInterval(i);
    })

    return (
        <svg
            className={'fireAnim'}
            viewBox="-4.5 -7 32 32"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d={fire2[frame]} className={'flame2'}/>
            <path d={fire1[frame]} className={'flame1'}/>
            <path d="m 9.9614724,18.390411 3.7411866,-0.04508 c -1.037256,1.882679 -2.906928,1.720405 -3.7411866,0.04508 z"/>
            <circle cx="5.8326693" cy="15.011953" r="1"/>
            <circle cx="16.541832" cy="14.884463" r="1"/>
        </svg>
    );
}