import {atom} from "recoil";
import {recoilPersist} from "recoil-persist";

const getDefaultLocationState = () => {
    let storedState = localStorage.getItem('storedState');
    if (storedState) {
        storedState = JSON.parse(storedState);
        const nodes = storedState.nodes;
        if (nodes)
            return nodes.reduce((acc, node) => ({
                ...acc,
                [node.id]: node
            }), {})
    }
    return {}
}

const { persistAtom } = recoilPersist({
    key: 'recoil-persist',
    storage: localStorage,
})

// nodes
export const editingNodeState = atom({key: 'editingNodeState', default: null, effects_UNSTABLE: [persistAtom]});
export const deletingNodeState = atom({key: 'deletingNodeState', default: null});
export const nodesState = atom({
    key: 'nodesState',
    default: getDefaultLocationState(), effects_UNSTABLE: [persistAtom]});
    // default: {"13366589-925d-402a-2c7a-5ab0a6aa8f10":{"iconType":"android","label":"Tavangar","id":"13366589-925d-402a-2c7a-5ab0a6aa8f10","anchor":[130.97756958007812,105.4022216796875],"name":"Foo","small":false,"coordinate":[5.730016194283963,58.96435219343238],"code":"coordinates: { longitude: 5.730016194283963 latitude: 58.96435219343238 }","locationFromMap":true},"f769ba8b-e328-4a98-2c5e-30a3056332b3":{"iconType":"squared_circle","label":"Sanniz","id":"f769ba8b-e328-4a98-2c5e-30a3056332b3","anchor":[330.15557861328125,144.849609375],"name":"Foo","small":false,"coordinate":[5.735917337005959,58.847555158712154],"code":"coordinates: { longitude: 5.735917337005959 latitude: 58.847555158712154 }","locationFromMap":true}}, effects_UNSTABLE: [persistAtom]});

// search
export const fromNodeState = atom({key: 'fromNodeState', default: null});
export const searchingState = atom({key: 'searchingState', default: null});
export const locatingState = atom({key: 'locatingState', default: null});
export const searchState = atom({key: 'searchState', default: null, effects_UNSTABLE: [persistAtom]});
export const resultsState = atom({key: 'resultsState', default: null, effects_UNSTABLE: [persistAtom]});

// UI
export const dismissedIntroState = atom({key: 'dismissedIntroState', default: false, effects_UNSTABLE: [persistAtom]});
export const draggingNodeState = atom({key: 'draggingNodeState', default: false});

// settings
export const longPressGpsState = atom({key: 'longpressGps', default: false, effects_UNSTABLE: [persistAtom]});
export const gpsOnMapState = atom({key: 'gpsOnMapState', default: true, effects_UNSTABLE: [persistAtom]});
export const gpsState = atom({key: 'gpsState', default: 'off', effects_UNSTABLE: [persistAtom]});
export const countdownOnOptionsState = atom({key: 'countdownOnOptionsState', default: null, effects_UNSTABLE: [persistAtom]});
export const keepOpenOptionsState = atom({key: 'keepOpenOptionsState', default: false, effects_UNSTABLE: [persistAtom]});
export const fuseState = atom({key: 'fuseState', default: false, effects_UNSTABLE: [persistAtom]});
export const walkSpeedState = atom({key: 'walkSpeedState', default: 1.3, effects_UNSTABLE: [persistAtom]});

