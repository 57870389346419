import React, {useState} from 'react';
import Map from "../../../Screens/MapView/Map";
import './LocationSearch.css'
import * as L from "leaflet";
import basicMapMarker from "../../../../resources/basic-map-marker.svg";
import useEffectPartialDeps from "../../../../utils/UseEffectPartialDeps";
import {LocationSearchField} from "./LocationSearchField";
import useEffectOnce from "../../../../utils/UseEffectOnce";

let targetMarker, map;
const LocationSearch = (props) => {
    const [selectedResult, setSelectedResult] = useState();
    const [coords, setCoords] = useState(null);
    const [inputFocus, setInputFocus] = useState(false);

    useEffectOnce(() => () => {targetMarker = undefined;})

    useEffectPartialDeps(() => {
        if (!coords || !map) return;
        setTargetMarker({
            lng: coords[0],
            lat: coords[1],
        });
    }, [coords])


    const onMapClick = (event) => {
        let c = event.latlng;
        setCoords(c);
        setTargetMarker(c);
    };

    const setTargetMarker = coords => {
            if (!targetMarker) {
                targetMarker =
                    L.marker(coords, {
                        icon: L.icon({
                            iconUrl: basicMapMarker,
                            iconSize: [50, 50],
                            iconAnchor: [25, 50],
                        }),
                        draggable: true
                    });
                targetMarker.on('dragend', e => {
                    setSelectedResult(null)
                    setCoords([
                        e.target._latlng.lng,
                        e.target._latlng.lat
                    ])
                });
                targetMarker.addTo(map);
            }
            else
                targetMarker.setLatLng(coords);
        }

        const handlePrevValue = () => {
                setTimeout(() => {
                    if (!map) return;
                    let c = (props.prevLoc && props.prevLoc.coordinate) || [5.3427, 60.38229];

                    mapMoveAndFocus(c);
                }, 20);
            };

    const mapMoveAndFocus = c => {
        setCoords(c)
        map.setView(new L.LatLng(...[...c].reverse()), 12)
    }

    const renderMap = () => {
        return (
            <Map
                onClick={onMapClick}
                passMap={m => {
                    map = m;
                    handlePrevValue();
                }}
                onMapFocus={() => {
                    if (inputFocus) setInputFocus(false);
                }}
            />
        )
    }

    const renderButton = () => {
        if (!coords) return;
        return (
            <div className="buttonWrapper">
                <button
                    className="confirmButton"
                    onClick={() => {
                        if (selectedResult)
                            props.chooseSearchLocation(selectedResult)
                        else
                            props.chooseMapLocation(coords)
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="35" viewBox="3 3 18 18" width="35">
                        <rect
                            x="4"
                            y="4"
                            height="16"
                            width="16"
                            fill="white"
                        />
                        <path
                            className={'safe'}
                            d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                            // fill="#29a71b"
                        />
                    </svg>
                </button>
            </div>
        )
    }
    if (!navigator.onLine) return (
        <div className="full screen centered">
            <p>Ser ut som du ikke har noe internetting.</p>
            <p>Da funker ikke dette.</p>
        </div>
    )
    return (
        <div className="locationSearch">
            <LocationSearchField
                selectedResult={selectedResult}
                setSelectedResult={r => {
                    if (!r.geometry || ! r.geometry.coordinates){
                        console.log('r is bloopy: ', r);
                        return;
                    }
                    mapMoveAndFocus([...r.geometry.coordinates])
                    setSelectedResult(r)
                }
                }
                inputFocus={inputFocus}
                setInputFocus={setInputFocus}
            />
            {renderMap()}
            {renderButton()}
        </div>
    );
}

export default LocationSearch;