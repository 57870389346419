import React, {useState} from "react";
import './Node.css';
import TouchController from "../../utils/TouchController";
import {Icon} from "../Icon/Icon";
import {clamp} from "../../utils/utils";
import useEffectOnce from "../../utils/UseEffectOnce";
import useEffectPartialDeps from "../../utils/UseEffectPartialDeps";

let grabInterval;

const Node = (props) => {
    const [touchController] = useState(new TouchController());
    const [locationRef] = useState(React.createRef());
    const [anchor, setAnchor] = useState(props.anchor || props.node.anchor || [20, 20]);
    const [touchTimer, setTouchTimer] = useState(0);
    const [moving, setMoving] = useState(false);
    const [moveEvent, setMoveEvent] = useState(null);
    const [touching, setTouching] = useState();
    const [invalidPos, setInvalidPos] = useState(false);
    const [anchorSection, setAnchorSection] = useState(null);

    useEffectOnce(() => {
        if (!locationRef) {
            console.log('no locationRef');
            return;
        }
        locationRef.current.addEventListener('touchstart', onTouchStart, {passive: false});
        locationRef.current.addEventListener('touchmove', onTouchMove, {passive: false});
        locationRef.current.addEventListener('touchend', onTouchEnd, {passive: false});
    })

    useEffectPartialDeps(() => {
        if (props.node.anchor) setAnchor(props.node.anchor)
    }, [props.node.anchor]
    )

    useEffectPartialDeps(() => {
        if (touchTimer >= 100) {
            clearInterval(grabInterval);
            grabInterval = null;
            setMoving(true);
            props.onDragStart && props.onDragStart();
        }
    }, [touchTimer]);

    useEffectPartialDeps(() => {
        if (!moving) return;
        touchController.onTouchMove(moveEvent);
        if (!props.containerRef) {
            console.log('no containerRef');
            return;
        }

        moveEvent.preventDefault();
        moveEvent.stopPropagation();

        const touchPos = touchController.getPosition();
        const rect = props.containerRef.current.getBoundingClientRect();
        const buffer = nodeSize / 2;
        const x = touchPos[0] - rect.top;
        const y = touchPos[1];

        let safeX = Math.max(buffer, x);
        let safeY = clamp(buffer, y, rect.width - buffer);

        let validX = safeX < rect.height - buffer;
        // console.log('validX: ', validX);
        if (!props.new) {
            if (validX && anchorSection !== 'safe') {
                setAnchorSection('safe');
                props.updateSection('safe');
            }
            if (!validX) {
                const section = safeY < rect.width / 3 ? 'delete' : 'edit';
                if (anchorSection !== section) {
                    setAnchorSection(section);
                    props.updateSection(section);
                }
            }
        }
        setInvalidPos(!validX);

        setAnchor([
            safeX,
            safeY
        ]);
    }, [moveEvent]);

    useEffectPartialDeps(() => {
        if (touching !== false) return;

        !props.new && props.onDragEnd && props.onDragEnd(anchorSection);

        if (grabInterval) {
            clearInterval(grabInterval);
            grabInterval = null;
        }

        if (!moving && touchTimer < 40) {
            props.onClick();
        // } else if (props.movable && moving) {
        } else if (moving) {
            if (props.new || !invalidPos)
                props.setAnchor(anchor, invalidPos);
            else
                setAnchor(props.node.anchor);
        }

        if (props.new) {
            setAnchor(props.node.anchor);
        }

        setTouchTimer(0);
        setMoving(false);
    }, [touching]);


    const nodeSize = props.node.small ? 60 : 120;

    let change = touchTimer * 2;

    // from rgb(0, 150, 250) to rgb(0, 250, 150)
    // or rgb(250, 150, 0) to rgb(150, 250, 0)
    let a = 150;
    let b = 250;
    if (change <= 100) {
        a = a + change;
    } else {
        a = 250;
        b = 250 - (change - 100);
    }

    let timerPercent = touchTimer;

    const longPressIndicatorSize = (nodeSize / 100) * timerPercent;

    let offset = (nodeSize - longPressIndicatorSize) / 2;
    let alpha = timerPercent / 100;
    let color = props.movable ?
        `rgba(0, ${a}, ${b}, ${alpha}` :
        `rgba(${b},0,  ${a}, ${alpha}`;

    const onTouchStart = e => {
        e.preventDefault();
        if (props.new) {
            setMoving(true);
            setMoveEvent(e);
        } else {
            grabInterval = setInterval(() => setTouchTimer(ov => Math.min(ov + 2, 100)), 10);
        }
        touchController.onTouchStart(e);
        setTouching(true);
    };


    const onTouchMove = e => {
        setMoveEvent(e);
    };

    const onTouchEnd = e => {
        e.stopPropagation();
        e.preventDefault();
        setTouching(false);
    };

    return (
        <div
            className={`node ${
                props.selected && !props.movable ? 'selected' : ''
            }${
                props.hidden ? ' hidden' : ''
            }${
                props.new ? ' new' : ''
            }${
                props.node.small ? ' node--small' : ''
            }${
                props.node.showLabel ? ' node--label' : ''
            }${
                props.movable && props.disableNodes && props.node?.disabled ? ' node--red' : ''
            }`}
            style={{
                height: `${nodeSize}px`,
                width: `${nodeSize}px`,
                top: anchor[0] - (nodeSize / 2),
                left: anchor[1] - (nodeSize / 2)
            }}
            onClick={props.onLocationClick}
            ref={locationRef}
        >
            <div
                style={{
                    position: 'absolute',
                    left: `${offset}px`,
                    top: `${offset - (props.showLabel ? 10 : 0)}px`,
                    backgroundColor: color,
                    height: longPressIndicatorSize + 'px',
                    width: longPressIndicatorSize + 'px',
                    borderRadius: '400000px'
                }}
            />
            <Icon
                type={props.node.iconType}
            />
            {
                (props.showLabel || props.node.showLabel) && (
                    <div className="node__label-wrapper">
                    <div className="node__label">
                        {props.node.label}
                    </div>
                    </div>
                )
            }
        </div>
    )


}

export default Node;