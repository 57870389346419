import React from 'react';
import './Monks.css'
import moment from "moment";

const lines = [[], [0, 0, 1, 0], [1, 0, 1, 1], [0, 1, 1, 1], [0, 0, 0, 1], [0, 0, 1, 1], [1, 0, 0, 1],]
const numbers = [[], [1], [3], [5], [6], [1, 6], [2], [1, 2], [2, 3], [1, 2, 3]]
const moveRight = line => [line[0] + 1, line[1], line[2] + 1, line[3]]
const moveDown = line => [line[0], line[1] + 2, line[2], line[3] + 2]
const flipHorizontal = line => [line[0] ? 0 : 1, line[1], line[2] ? 0 : 1, line[3]]
const flipVertical = line => [line[0] ? 0 : 1, line[1] ? 0 : 1, line[2] ? 0 : 1, line[3] ? 0 : 1]

export const Monks = ({time}) => {
    if (!time) return <></>
    const funx = [
        i => (i > 10) ? <></> : numbers[i].map(j => lines[j]).map(moveRight).map(renderLine),
        i => (i > 10) ? <></> : numbers[i].map(j => lines[j]).map(flipHorizontal).map(renderLine),
        i => (i > 10) ? <></> : numbers[i].map(j => lines[j]).map(flipHorizontal).map(flipVertical).map(moveDown).map(moveRight).map(renderLine),
        i => (i > 10) ? <></> : numbers[i].map(j => lines[j]).map(flipVertical).map(moveDown).map(renderLine),
    ]

    const renderNumber = num => {
        let things = []
        let strings =
            `${num}`.split('').reverse();
        if (strings.length > 4)
            strings = ['7', '7', '7', '7'];
        for (let i = 0; i < strings.length; i++) {
            const s = strings[i];
            const n = parseInt(s);
            if (n)
                things.push(funx[i](n))
        }
        return things;
    }

    const renderLine = ([x1, y1, x2, y2]) => x1 === undefined ? <></> : (
        <line
            strokeLinecap="round"
            key={`${x1},${y1},${x2},${y2}`}
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
        />
    )

    const number = Math.max(time.diff(moment(), 'seconds'), 0);

    return (
        <div className="monks">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-0.1 -0.1 2.2 3.2"
                strokeWidth="0.15"
                filter="url(#boop)"
            >
                <defs>
                    <filter id="boop">
                        <feTurbulence
                            type="fractalNoise"
                            numOctaves="1"
                            result="turbulence"
                            seed="42"
                            // baseFrequency=".5"
                            // baseFrequency=".3"
                            // baseFrequency="2,.02"
                            // baseFrequency="1,.01"
                            // baseFrequency=".01,1"
                            baseFrequency="2,.01"
                            // baseFrequency=".9,.01"
                        />
                        <feDisplacementMap
                            in="SourceGraphic"
                            in2="turbulence"
                            scale="32"
                            xChannelSelector="R"
                            yChannelSelector="G"
                            result="BOOP1"
                        />
                        {/*<feMorphology operator="dilate" radius="1" in="SourceGraphic" result="BIGGER" />*/}
                        {/*<feComposite in="BIGGER" in2="BOOP1" operator="out"/>*/}
                        <feComposite in="SourceGraphic" in2="BOOP1" operator="out"/>
                    </filter>
                </defs>
                {renderLine([1, 0, 1, 3])}
                {renderNumber(number)}
            </svg>
        </div>
    );
}