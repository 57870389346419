import React from 'react';

export const LocationSearchResult = ({loc, onClick, selected }) => (
    <button
        key={loc.properties.id}
        className={`locationSearch__result${selected ? ' active' : ''}`}
        onClick={onClick}
    >
        <div className="locationSearch__result__label">
            {loc.properties.label}
        </div>
    </button>
);