import React, {useState} from 'react';
import {findLocationOptions} from "../../../../utils/api";
import {LocationSearchResult} from "./LocationSearchResult";
import {TextInput} from "../../../Input/TextInput";

let searchTimeout;

export const LocationSearchField = ({
                                        selectedResult,
                                        setSelectedResult,
                                        inputFocus,
                                        setInputFocus
                                    }) => {
    const [searchString, setSearchString] = useState('');
    const [results, setResults] = useState([]);
    const onChange = s => {
        setSearchString(s)
        if (searchTimeout)
            clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            doSearch(s);
        }, 500);
    };

    const doSearch = (searchStringArg) => {
        const s = searchStringArg || searchString;
        if (s.length > 0)
            findLocationOptions(s).then(r => {
                if (selectedResult && r.filter(l => l.properties.id === selectedResult.properties.id).length === 0)
                    setSelectedResult(undefined);
                setResults(r);
            });
    }

    const renderResults = () => {
        if (!inputFocus || !results || results.length < 1) return '';
        return (
            <div className="locationSearch__results">
                {
                    results.map(loc => (
                        <LocationSearchResult
                            key={loc.properties.id}
                            loc={loc}
                            onClick={() => {
                                setSelectedResult(loc)
                            }}
                            selected={selectedResult && selectedResult.properties.id === loc.properties.id}
                        />
                    ))
                }
            </div>
        )
    };

    return (
        <div
            className="locationSearchField"
        >
            <TextInput
                className={'locationSearch__locationSearchInput'}
                placeholder={'Søk'}
                value={searchString}
                onChange={onChange}
                onFocus={() => {if (!inputFocus) setInputFocus(true)}}
            />
            {renderResults()}
        </div>
    );
}