import React, {useState} from 'react';
import {Routes, Route, Navigate} from "react-router-dom";
import {Blobs} from "./Screens/Blobs/Blobs";
import {MapView} from "./Screens/MapView/MapView";
import {Results} from "./Screens/Results/Results";
import {useRecoilState} from "recoil";
import {deletingNodeState, editingNodeState} from "./States";
import {EditNode} from "./Screens/EditNode/EditNode";
import {Settings} from "./Screens/Settings/Settings";
import {TestScreen} from "./Screens/TestScreen/TestScreen";
import {DeleteNode} from "./Screens/Blobs/DeleteNode/DeleteNode";
import {FireScreen} from "./Screens/FireScreen";
import {Privacy} from "./Screens/Privacy";
import {Locator} from "./Locator/Locator/Locator";

export const Routing = props => {
    const [editingNode] = useRecoilState(editingNodeState);
    const [deletingNode] = useRecoilState(deletingNodeState);
    const [locator] = useState(new Locator());


    const route = (key, Component) => (
        <Route
            path={key}
            element={<Component{...props} locator={locator}/>}
        />
    );

    return (
        <Routes>
            {route('fire', FireScreen)}
            {route('test', TestScreen)}
            {route('settings', Settings)}
            {route('results', Results)}
            {route('blobs', editingNode ? EditNode : deletingNode ? DeleteNode : Blobs)}
            {route('map',  MapView)}
            {route('privacy',  Privacy)}
            {route('*', () => <Navigate to={'blobs'}/>)}
            {/*{route('/', () => <Redirect to={props.nodes.length === 0 ? 'help' : 'locations'}/>, true)}*/}
        </Routes>
    )
}