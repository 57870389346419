import React from 'react';
import {Icon} from "../Icon/Icon";

export const DeleteOrEditNodeUi = ({draggingNode, hoveredSection}) => (
    <div
        className={`deleteOrEditNodeUi ${draggingNode ? '' : 'hidden'}`}
    >
        <div className={`dropZone deleteNodeDropZone ${hoveredSection === 'delete' ? 'active' : ''}`}>
            <Icon type={'trash'}/>
        </div>
        <div className={`dropZone editNodeDropZone ${hoveredSection === 'edit' ? 'active' : ''}`}>
            <Icon type={'edit'}/>
        </div>
    </div>
);