import React from 'react';
import useEffectOnce from "../utils/UseEffectOnce";
import './App.css';
import './Input/Input.css';
import {BrowserRouter} from "react-router-dom";
import {Navigation} from "./Navigation/Navigation";
import {Routing} from "./Routing";
import './Loader/Loader.css'
import {RecoilRoot} from "recoil";
import {SearchController} from "./SearchController";
import {initGa} from "./ga/useAnalyticsEventTracker";

function App() {
    useEffectOnce(initGa)
    return (
        <BrowserRouter>
            <RecoilRoot>
                <div className={'App'}>
                    <SearchController/>
                    <Navigation/>
                    <Routing/>
                </div>
            </RecoilRoot>
        </BrowserRouter>
    );
}

export default App;
