import React, {useState} from 'react';
import './HelpScreen.css'
import {Icon} from "../Icon/Icon";
import {dismissedIntroState} from "../States";
import {useRecoilState} from "recoil";

export const HelpScreen = props => {
    const [dismissedIntro, setDismissedIntro] = useRecoilState(dismissedIntroState);
    const [closed, setClosed] = useState(false);
    if (closed) return <></>;
    const info = props.locations === undefined
    const noLocs = !info && props.locations.length === 0;
    if (!noLocs && !info && dismissedIntro) return <></>;

    const close = () => {
        if (props.close) props.close();
        else setClosed(true);
        if (!noLocs) setDismissedIntro(true);
    }

    let content;
    if (noLocs)
        content = (
            <>
                <p>Før du kan gjøre et søk må du legge til en lokasjon.</p>
                <p>Det gjør du ved å dra <span className={'noWrap'}>
                        <Icon type={'plus'}/> ikonet
                    </span> fra bunnmenyen ut her.
                </p>
            </>
        )

    else if (info)
        content = (
            <>
                <p>
                    Du gjør et søk ved å trykke på lokasjoner, enten på{' '}
                    <span className="noWrap">kartet <Icon type={'map'}/></span>
                    eller på <span className="noWrap">snarveisiden <Icon type={'blobs'}/></span>.
                </p>
                <p>På disse sidene kan du og legge til nye lokasjoner.</p>
                <p>
                    På snarveisiden <Icon type={'blobs'}/> kan du flytte på snarveier, eller redigere eller slette
                    lokasjonene
                    ved å holde på dem til de blir helt farget, og så flytte på dem.
                </p>
                <p>
                    Du kan velge startpunkt hvis du skrur av "Søk fra GPS med ett trykk" i{' '}
                    <span className="noWrap">instillingene <Icon type={'settings'}/></span>.
                    Da søker du ved å trykke først på lokasjonen du vil reise fra, så på den du vil reise til.
                </p>
                <p>Hvis du vil søke både med og uten GPS om hverandre kan du skru på 'Fast GPS "lokasjon" nede
                    i midten' i innstillingene. Den nye knappen erstatter{' '}
                    <span className={'noWrap'}>
                        ny lokasjon knappen <Icon type={'plus'}/>
                    </span>.
                </p>
                <p>
                    Du ser resultatene på{' '}
                    <span className="noWrap">resultatskjermen <Icon type={'results'}/></span>.
                    Der kan du oppfriske dataene ved å refreshe nettleseren eller trykke på{' '}
                    <span className="noWrap"><Icon type={'refresh'}/> knappen</span>.
                </p>
                <p>
                    Hvis tiden på et reisealternativ blir rød betyr det at det kan være på tide å søke
                    på nytt. Dataene er estimater fra EnTur, og ting kan endre seg; spesielt når det
                    begynner å nærme seg.
                </p>
            </>
        )
    else if (!dismissedIntro)
        content = (
            <>
                <p>
                    Du gjør et søk ved å trykke på lokasjoner, enten på{' '}
                    <span className="noWrap">kartet <Icon type={'map'}/></span>
                    eller på <span className="noWrap">snarveisiden <Icon type={'blobs'}/></span>.
                </p>
                <p>
                    Du finner litt mer info under{' '}
                    <span className="noWrap">instillinger <Icon type={'settings'}/></span>
                </p>
            </>
        )

    return content ? (
        <div className={`helpScreen ${info ? 'solid' : ''}`}>
            <div className="helpScreen__contentWrapper">
                <div className="closeButtonWrapper">
                    <button
                        className={'closeFullscreenButton'}
                        onClick={close}
                    >
                        <Icon type={'close'}/>
                    </button>
                </div>
                {content}
            </div>
        </div>
    ) : (<></>);
}