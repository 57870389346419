import React from 'react';
import './Settings.css'
import { ResetButton } from 'grevlingui';
import {
    countdownOnOptionsState,
    fuseState,
    gpsState,
    keepOpenOptionsState,
    gpsOnMapState,
    walkSpeedState
} from "../../States";
import {useRecoilState} from "recoil";
import {Icon} from "../../Icon/Icon";
import {HelpScreen} from "../../HelpScreen/HelpScreen";
import {ToggleSwitch} from "../../Input/ToggleSwitch";
import {RadioGroup} from "../../Input/Radio/RadioGroup";
import {useNavigate, useLocation} from "react-router-dom";
import {wrapperAppFunction} from "../../../utils/utils";
// import {ImportExportLocations} from "./ImportExportLocations";


export const Settings = () => {
    const [gpsMode, setGpsMode] = useRecoilState(gpsState);
    const [showEtaDefault, setShowEtaDefault] = useRecoilState(countdownOnOptionsState);
    const [keepOpenOptions, setKeepOpenOptions] = useRecoilState(keepOpenOptionsState);
    const [gpsOnMap, setGpsOnMap] = useRecoilState(gpsOnMapState);
    const [fuse, setFuse] = useRecoilState(fuseState);
    const [walkSpeed, setWalkSpeed] = useRecoilState(walkSpeedState);
    const navigate = useNavigate();
    const location = useLocation();

    const renderCheck = (label, checked, setChecked) => (
        <ToggleSwitch
            label={label}
            active={checked}
            onChange={setChecked}
        />
    );

    return (
        <div className="settings screen">
            {location.state && location.state.showInfo && (
                <HelpScreen
                    close={() =>
                        navigate('/settings', {state: {showInfo: false}, replace: true})
                    }
                />
            )}
            <div className="settingsContent">
                <div>
                    <p>GPS</p>
                    <RadioGroup
                        onSelect={mode => {
                            if (['startFromGps', 'centralButton'].includes(mode))
                                wrapperAppFunction({request: 'getLocationAccess'})
                            setGpsMode(mode)
                        }}
                        selected={gpsMode}
                        options={[
                            {label: 'Av', key: 'off'},
                            {label: 'Søk fra GPS med ett trykk', key: 'startFromGps'},
                            {label: 'Fast GPS "lokasjon" nede i midten', key: 'centralButton'}
                        ]}
                    />
                    {renderCheck(
                        'Vis posisjon på kartet',
                        gpsOnMap,
                        val => {
                            if (val)
                                wrapperAppFunction({request: 'getLocationAccess'})
                            setGpsOnMap(val);
                        }
                    )}
                    <br/>
                    <p>I resultatene</p>
                    {renderCheck(
                        'Vis nedtelling til når du bør dra',
                        showEtaDefault, setShowEtaDefault
                    )}
                    {renderCheck(
                        'Vis resterende tid som loading bar',
                        fuse, setFuse
                    )}
                    {renderCheck(
                        'Behold åpne reisealternativer når du søker på ny',
                        keepOpenOptions, setKeepOpenOptions
                    )}
                    <br/>
                    <p>Ganghastighet: {walkSpeed} m/s</p>
                    <div className="walkspeedControls">
                            <input
                                type="range"
                                min="1"
                                max="5"
                                step=".1"
                                value={walkSpeed}
                                onChange={e => setWalkSpeed(e.target.value)}
                                className="walkSpeedSlider"
                                id="myRange"
                            />
                        <ResetButton
                            onClick={() => setWalkSpeed(1.3)}
                        />
                    </div>
                    <button
                        className={'infoButton'}
                        onClick={() => navigate('/settings', {state: {showInfo: true}, replace: true})}
                    >
                        <Icon type={'help'}/>
                        Info
                    </button>
                </div>
                {/*<ImportExportLocations/>*/}
            </div>
        </div>
    );
}