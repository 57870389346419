import React from 'react';
import './GpsLoader.css'

export const GpsLoader = ({overlay}) => {
    const loader =  (
        <div className="gpsLoaderWrapper">
            <svg
                className={'gpsLoader'}
                fill="none"
                viewBox="10 0 200 200"
                // viewBox="-100 -110 400 400"
                xmlns="http://www.w3.org/2000/svg"
            >
                <mask id="globeMask">
                    <circle
                        id="globe"
                        cx="112.35"
                        cy="139.1"
                        r="53.538"
                        fill="#fff"/>
                    <g
                        fill="#000"
                        strokeWidth=".26458px"
                    >
                        <path
                            d="m141.05 120c-4.2757-3.5548-6.8416-7.3142-11.76-8.9535-12.004-4.2328-24.033 12.329-19.043 20.847 2.0266 2.9713 3.4316 7.0286 6.7486 8.419 2.6427 1.1078 6.042-2.2499 8.5526-0.86863 2.1818 1.2003 2.82 4.2467 3.3409 6.6817 0.33104 1.5478 0.17097 3.2055-0.20045 4.744-1.2371 5.1246-4.8049 9.4144-6.6149 14.366-1.063 2.908-2.27 5.864-2.4722 8.9535-0.0632 0.96493-0.33617 2.2472 0.40091 2.8732 1.4095 1.197 3.7826 0.42349 5.5458-0.13364 2.9863-0.94361 5.2664-3.3952 7.8176-5.2118 8.4114-6.5414 6.7451-2.1686 10.691-12.695 2.2708-4.1411 6.4393-6.9407 9.2208-10.758 1.4532-1.9942 1.3193-5.375-0.43807-8.2361-1.4703-2.3937-2.0839-3.6902-2.836-6.3969-0.84549-3.7797 0.46489-11.576-2.7395-13.698-1.7646-1.085-4.6212 1.3911-6.214 0.0668z"
                        />
                        <path
                            d="m144.93 166.74c-1.7261 0.67044-3.5425 0.82165-4.4768 2.4388-0.84386 1.4608-3.1864 3.6863-4.3097 4.6104-0.51601 0.42448 0.69377 1.2857 1.3363 1.1025 1.8277-0.52107 3.208-2.5903 4.6438-4.0424 1.2698-1.2842 5.6863-5.2279 2.8063-4.1093z"
                        />
                        <path
                            d="m142.97 94.464s-1.5399 4.4743-2.7903 5.1576c-1.1636 0.63597-2.622-0.40968-3.9422-0.53454-1.1254-0.10644-2.471 0.80879-3.5805 0.29831-0.43048-0.19808-3.0844-1.2132-4.6747-0.24226-1.4898 0.9096-1.464 1.028-3.5047 1.9485-0.48895 0.22054 0.3804 1.1274 0.13363 1.6036-0.486 0.93796-6.5986-2.4385-5.9866 0.42186 0.4756 2.2228-0.1407 3.8293 0.24031 5.2576 0.66974 2.5106 8.3414-6.3212 17.038-0.0668 1.695 1.2189-0.57808 3.7698 0.33409 4.5436 0.94397 0.80079 3.0028 0.50494 3.675-0.53453 0.88048-1.3616-2.1679-3.905-0.74576-5.3387 0.71464-0.72041 3.486 2.9934 4.0173 3.277 2.5177 1.3435-0.55052 1.2208 3.5439 3.3981 0.80127 0.4261-0.16772-2.6148 1.8041-2.4722 1.4034 0.10148 3.5521 3.8075 3.0068 5.9468-0.23186 0.90959-2.8143 0.13676-2.4567 2.0632 0.47862 2.5783 2.3309 8.1866 4.3276 18.249 0.21281 1.0725 0.73545 2.5081 1.8041 2.7395 2.0836 0.45121 4.1279-1.7061 5.4122-3.4077 1.0638-1.4094-0.90363-4.1381-2.5688-6.1803-1.5121-1.8545-2.7369-4.3623-2.8434-5.8468-0.0265-0.36974 0.74114-0.91705 0.93544-0.60136 1.4247 2.3148 2.598 5.8067 4.6104 8.2185 0.53712 0.64375 1.9072 0.76578 1.9377 1.6036 0.21599 5.9248 0.18362 22.274 0.91678 22.394 13.975-19.62 9.1445-63.027-20.644-61.896z"
                        />
                        <path
                            d="m129.98 94.169c0.57385-0.09785 1.5258-0.2921 1.5119-0.87407-0.0102-0.42874-0.32795-1.1725 0.92131-1.0394 2.2916 0.24406 0.69155 0.36997 2.8821 1.0867 0.84202 0.2755 1.4138 1.2254 2.2915 1.3465 0.87879 0.12125 1.4658 0.29291 1.8899 1.7245 0.21391 0.72217 1.6039 2.1242-0.0709 2.0552-2.7703-0.11401-3.1898-1.3315-3.3545-2.2442-0.0982-0.54411-0.54274-0.99387-0.96856-1.3465-0.39192-0.3246-0.73002 0.11496-0.54334 0.85045 0.31408 1.2374 0.47948 2.1267-0.87407 1.819-0.7004-0.15923-1.3679-0.46504-2.0789-0.56696-0.42099-0.06035-0.86083-0.11737-1.2757-0.02363-2.0473 0.46263-2.2507-2.4602-0.3307-2.7876z"
                        />
                        <path
                            d="m120.68 86.051c-0.10376 0-3.8279 2.6663-4.7182 3.047-1.7189 0.73485-3.7391 0.2388-5.5793 0.56795-1.7548 0.31389-3.5233 0.67403-5.1783 1.3363-6.83 2.7332 2.1453-5.4787 2.3557-5.6171 0.28972-3.9833 13.676-2.9799 13.12 0.66585z"
                        />
                        <path
                            d="m84.08 93.436c-0.62908 1.1766-2.6477 5.7093 2.0517 1.6776 1.0658-0.91437 2.7694-2.4097 4.2244-2.9423 2.9843-1.0925 0.81439 0.59527 1.587 0.41462 4.3335-1.0132 2.0924 3.6361 0.74773 4.0102-0.9585 0.2667-1.7841 0.89684-2.3541 1.2578-17.566 11.124-14.56 10.446-18.348 15.999-0.27716 0.40633-0.65782 1.0119-0.83472 1.6238-1.2403 4.2904-2.0227 2.3378-2.4568 1.5119-1.147-2.1817-4.9031 2.0136-5.4334 4.2522-0.26847 1.1333-0.01967 4.3972 1.0867 1.9844 2.2569-4.922 1.6124 0.60412 1.1812 3.1656-0.40919 2.4307 0.75934 5.2706 2.0789 6.7563 1.7134 1.9292 1.378 0.42431 2.2679-1.9371 1.4182-3.7635 8.6499 1.6436 10.442 5.3389 1.8322 3.7789 4.5908 5.3893 6.8036 5.1499 5.0121-0.54226 6.6721 6.5256 5.7169 8.5517-1.0858 2.3031-0.71734 4.802-1.3229 6.9926-0.53621 1.9396 0.57671 4.7962-1.6064 5.8114-1.0623 0.494-1.4147 1.2793-1.5119 2.1261-1.1216 9.7701 0.33227 14.328 3.5908 17.009 1.0577 0.87036 1.8687 2.0077 2.8821 2.9293 1.3496 1.2274-0.08966 1.186-0.7087 0.66146-10.558-8.9461-12.128-9.5941-16.725-22.726-1.154-3.2958-3.7736-6.3812-5.9531-8.2682-4.4622-3.8633-3.9738-9.8658-3.8743-15.072 0.0304-1.5898 0.40751-2.9595-0.33073-3.7325-2.0478-2.1443-2.9598-5.5618-4.5357-7.465-0.69403-0.83819-3.295 0.15672-3.3386 0.25853-6.079-9.4574 13.58-40.522 24.674-35.34z"
                        />
                    </g>
                </mask>

                <path
                    d="m -41.407051,9.424075 h 4.69319 l 1.161463,-3.98218 0.995547,3.98218 h 4.887203 v 8.752716 l 2.4188,0.0019 -0.01582,-6.220904 9.839083,0.0031 -0.03382,6.223102 1.775137,0.001 -0.01223,-6.289788 9.685733,0.0028 -0.137511,13.923271 h -9.582584 l -0.02081,-5.637597 h -1.775298 l 0.06708,5.465719 h -9.839084 l -0.01736,-5.465719 h -2.351274 v 7.999502 h -4.88721 l 0.07064,1.272085 c 2.493626,0 4.559227,2.169862 4.559227,3.821337 l -4.906456,-0.0053 -0.995489,3.040534 -0.995599,-3.042647 -4.789012,-0.0052 c 0,-1.651424 2.142267,-3.808711 4.446933,-3.808711 l 0.06558,-1.272085 h -4.306034 v -8.039846 h -2.180163 l -0.0045,6.123909 h -9.260823 l -0.0092,-6.123909 h -2.665608 l 0.03369,6.182233 h -8.733009 l -0.116593,-14.260593 9.024548,-0.0011 -0.03843,6.123345 2.325321,-2.99e-4 -0.05406,-6.124236 9.377695,-0.0017 0.03587,6.123511 2.2652,-0.0013 z"
                    className="sat sat1"
                />
                <path
                    d="m -38.119775,9.4823599 h 7.201331 v 8.7527161 l 0.938537,0.0019 -0.01582,-4.043866 10.554664,0.0031 -0.03379,4.046065 1.049462,0.001 -0.01226,-4.112751 7.045064,0.0028 0.04791,4.114554 0.739503,0.001 -10e-7,-4.194534 5.9340207,0.0037 v 9.946867 h -5.7822297 l -0.01661,-3.762927 h -0.835442 l 0.01723,3.762927 h -7.183805 l -0.02084,-3.762926 h -1.049624 l 0.06709,3.591048 h -10.554669 l -0.01737,-3.591048 h -0.871013 v 7.999502 h -1.663599 c 3.931636,0 6.882167,3.884411 6.882167,6.254891 l -17.217365,-0.01262 c 0,-2.149196 2.529424,-6.242265 6.32741,-6.242265 h -1.529949 V 20.20165 h -0.936401 l -0.0043,4.249238 h -10.21894 l -0.009,-4.249238 h -1.12919 l 0.0337,4.307561 h -7.07709 l -0.0226,-4.307561 h -1.20808 l 0.006,4.249238 h -5.54066 l -0.0347,-10.031167 5.45871,-0.0015 0.0429,3.829845 1.19095,-4.18e-4 -0.009,-3.947283 7.36862,-0.0011 -0.0384,3.946308 0.78891,-2.99e-4 -0.054,-3.947199 10.335621,-0.0017 0.03586,3.946474 1.02144,-0.0013 z"
                    className="sat sat2"
                />
                <path
                    d="m -52.558166,21.657431 h 1.659393 l -0.01496,-1.455781 h 0.496124 l -0.02455,1.455781 h 8.636732 l -0.0045,-1.343594 h 0.900611 l 0.02158,1.343594 h 13.294572 l 0.01159,-1.31511 h 0.895632 l 0.01717,1.31511 h 8.848442 v -1.181443 h -34.773032 c 3.953115,-2.733343 5.150222,-3.642459 11.500098,-5.250079 1.785538,-6.663718 12.4252,-6.98066 14.29566,0 4.554609,0.997532 7.630001,2.462248 11.406154,5.238208 l -2.013607,0.02225 -0.0092,1.19252 2.007715,-0.02117 c -7.538708,7.538711 -29.601001,7.550345 -37.151634,-2.88e-4 z"
                    className="sat sat3"
                />
                <path
                    d="m -40.387812,9.4822859 h 11.737405 v 8.7527151 l 2.418798,0.0019 -0.01582,-6.220904 6.806365,0.0031 -0.03379,6.223103 1.775141,0.001 -0.01226,-6.289789 5.775125,0.0028 0.04791,6.291592 1.283763,10e-4 -10e-7,-6.371571 5.934021,0.0037 v 13.998576 h -5.78223 l -0.01661,-5.637598 h -1.621594 l 0.01723,5.637598 h -5.671973 l -0.02084,-5.63752 h -1.775303 l 0.06709,5.46572 h -6.806367 l -0.01737,-5.46572 h -2.351273 v 7.999503 h -3.931637 c 3.931637,0 6.882167,3.884411 6.882167,6.254891 l -17.217365,-0.01262 c 0,-2.149196 2.529424,-6.242265 6.32741,-6.242265 h -3.797986 v -8.03984 h -2.180163 l -0.0043,6.12391 h -6.70714 l -0.0092,-6.12391 h -2.665605 l 0.03368,6.182233 h -5.540681 l -0.02258,-6.182233 h -1.208079 l 0.0059,6.12391 h -5.540665 l -0.03467,-14.082876 5.458708,-0.0015 0.04282,6.006882 1.190958,-4.18e-4 -0.009,-6.12432 5.832212,-0.0011 -0.03842,6.123344 2.325322,-2.98e-4 -0.05404,-6.124236 6.823825,-0.0017 0.03586,6.123512 2.265203,-0.0013 z"
                    className="sat sat4"
                />
                <circle
                    mask="url(#globeMask)"
                    id="circle884"
                    cx="112.35"
                    cy="139.1"
                    r="53.538"
                    stroke="#fff"
                    strokeWidth="2"
                />
                {/*<g mask="url(#globeMask)">*/}
                {/*    <g*/}
                {/*        fill="#000"*/}
                {/*        strokeWidth=".26458px"*/}
                {/*    >*/}
                {/*        <path*/}
                {/*            d="m141.05 120c-4.2757-3.5548-6.8416-7.3142-11.76-8.9535-12.004-4.2328-24.033 12.329-19.043 20.847 2.0266 2.9713 3.4316 7.0286 6.7486 8.419 2.6427 1.1078 6.042-2.2499 8.5526-0.86863 2.1818 1.2003 2.82 4.2467 3.3409 6.6817 0.33104 1.5478 0.17097 3.2055-0.20045 4.744-1.2371 5.1246-4.8049 9.4144-6.6149 14.366-1.063 2.908-2.27 5.864-2.4722 8.9535-0.0632 0.96493-0.33617 2.2472 0.40091 2.8732 1.4095 1.197 3.7826 0.42349 5.5458-0.13364 2.9863-0.94361 5.2664-3.3952 7.8176-5.2118 8.4114-6.5414 6.7451-2.1686 10.691-12.695 2.2708-4.1411 6.4393-6.9407 9.2208-10.758 1.4532-1.9942 1.3193-5.375-0.43807-8.2361-1.4703-2.3937-2.0839-3.6902-2.836-6.3969-0.84549-3.7797 0.46489-11.576-2.7395-13.698-1.7646-1.085-4.6212 1.3911-6.214 0.0668z"*/}
                {/*        />*/}
                {/*        <path*/}
                {/*            d="m144.93 166.74c-1.7261 0.67044-3.5425 0.82165-4.4768 2.4388-0.84386 1.4608-3.1864 3.6863-4.3097 4.6104-0.51601 0.42448 0.69377 1.2857 1.3363 1.1025 1.8277-0.52107 3.208-2.5903 4.6438-4.0424 1.2698-1.2842 5.6863-5.2279 2.8063-4.1093z"*/}
                {/*        />*/}
                {/*        <path*/}
                {/*            d="m142.97 94.464s-1.5399 4.4743-2.7903 5.1576c-1.1636 0.63597-2.622-0.40968-3.9422-0.53454-1.1254-0.10644-2.471 0.80879-3.5805 0.29831-0.43048-0.19808-3.0844-1.2132-4.6747-0.24226-1.4898 0.9096-1.464 1.028-3.5047 1.9485-0.48895 0.22054 0.3804 1.1274 0.13363 1.6036-0.486 0.93796-6.5986-2.4385-5.9866 0.42186 0.4756 2.2228-0.1407 3.8293 0.24031 5.2576 0.66974 2.5106 8.3414-6.3212 17.038-0.0668 1.695 1.2189-0.57808 3.7698 0.33409 4.5436 0.94397 0.80079 3.0028 0.50494 3.675-0.53453 0.88048-1.3616-2.1679-3.905-0.74576-5.3387 0.71464-0.72041 3.486 2.9934 4.0173 3.277 2.5177 1.3435-0.55052 1.2208 3.5439 3.3981 0.80127 0.4261-0.16772-2.6148 1.8041-2.4722 1.4034 0.10148 3.5521 3.8075 3.0068 5.9468-0.23186 0.90959-2.8143 0.13676-2.4567 2.0632 0.47862 2.5783 2.3309 8.1866 4.3276 18.249 0.21281 1.0725 0.73545 2.5081 1.8041 2.7395 2.0836 0.45121 4.1279-1.7061 5.4122-3.4077 1.0638-1.4094-0.90363-4.1381-2.5688-6.1803-1.5121-1.8545-2.7369-4.3623-2.8434-5.8468-0.0265-0.36974 0.74114-0.91705 0.93544-0.60136 1.4247 2.3148 2.598 5.8067 4.6104 8.2185 0.53712 0.64375 1.9072 0.76578 1.9377 1.6036 0.21599 5.9248 0.18362 22.274 0.91678 22.394 13.975-19.62 9.1445-63.027-20.644-61.896z"*/}
                {/*        />*/}
                {/*        <path*/}
                {/*            d="m129.98 94.169c0.57385-0.09785 1.5258-0.2921 1.5119-0.87407-0.0102-0.42874-0.32795-1.1725 0.92131-1.0394 2.2916 0.24406 0.69155 0.36997 2.8821 1.0867 0.84202 0.2755 1.4138 1.2254 2.2915 1.3465 0.87879 0.12125 1.4658 0.29291 1.8899 1.7245 0.21391 0.72217 1.6039 2.1242-0.0709 2.0552-2.7703-0.11401-3.1898-1.3315-3.3545-2.2442-0.0982-0.54411-0.54274-0.99387-0.96856-1.3465-0.39192-0.3246-0.73002 0.11496-0.54334 0.85045 0.31408 1.2374 0.47948 2.1267-0.87407 1.819-0.7004-0.15923-1.3679-0.46504-2.0789-0.56696-0.42099-0.06035-0.86083-0.11737-1.2757-0.02363-2.0473 0.46263-2.2507-2.4602-0.3307-2.7876z"*/}
                {/*        />*/}
                {/*        <path*/}
                {/*            d="m120.68 86.051c-0.10376 0-3.8279 2.6663-4.7182 3.047-1.7189 0.73485-3.7391 0.2388-5.5793 0.56795-1.7548 0.31389-3.5233 0.67403-5.1783 1.3363-6.83 2.7332 2.1453-5.4787 2.3557-5.6171 0.28972-3.9833 13.676-2.9799 13.12 0.66585z"*/}
                {/*        />*/}
                {/*        <path*/}
                {/*            d="m84.08 93.436c-0.62908 1.1766-2.6477 5.7093 2.0517 1.6776 1.0658-0.91437 2.7694-2.4097 4.2244-2.9423 2.9843-1.0925 0.81439 0.59527 1.587 0.41462 4.3335-1.0132 2.0924 3.6361 0.74773 4.0102-0.9585 0.2667-1.7841 0.89684-2.3541 1.2578-17.566 11.124-14.56 10.446-18.348 15.999-0.27716 0.40633-0.65782 1.0119-0.83472 1.6238-1.2403 4.2904-2.0227 2.3378-2.4568 1.5119-1.147-2.1817-4.9031 2.0136-5.4334 4.2522-0.26847 1.1333-0.01967 4.3972 1.0867 1.9844 2.2569-4.922 1.6124 0.60412 1.1812 3.1656-0.40919 2.4307 0.75934 5.2706 2.0789 6.7563 1.7134 1.9292 1.378 0.42431 2.2679-1.9371 1.4182-3.7635 8.6499 1.6436 10.442 5.3389 1.8322 3.7789 4.5908 5.3893 6.8036 5.1499 5.0121-0.54226 6.6721 6.5256 5.7169 8.5517-1.0858 2.3031-0.71734 4.802-1.3229 6.9926-0.53621 1.9396 0.57671 4.7962-1.6064 5.8114-1.0623 0.494-1.4147 1.2793-1.5119 2.1261-1.1216 9.7701 0.33227 14.328 3.5908 17.009 1.0577 0.87036 1.8687 2.0077 2.8821 2.9293 1.3496 1.2274-0.08966 1.186-0.7087 0.66146-10.558-8.9461-12.128-9.5941-16.725-22.726-1.154-3.2958-3.7736-6.3812-5.9531-8.2682-4.4622-3.8633-3.9738-9.8658-3.8743-15.072 0.0304-1.5898 0.40751-2.9595-0.33073-3.7325-2.0478-2.1443-2.9598-5.5618-4.5357-7.465-0.69403-0.83819-3.295 0.15672-3.3386 0.25853-6.079-9.4574 13.58-40.522 24.674-35.34z"*/}
                {/*        />*/}
                {/*    </g>*/}
                {/*</g>*/}
            </svg>
        </div>
    );

    return overlay ? (
        <div className="loader__overlay">
            {loader}
            <div className="loader__displacer"/>
        </div>
    ) : loader;
}