import gpsMarker from '../../../resources/gps_marker.png';
import * as L from "leaflet";
import {randomIdGenerator} from "../../../utils/utils";


export class Locator {
    maps = {};
    activeMapId;
    locateInterval;
    locating;

    subscribe = (map) => {
        if (!map) return;

        let id = randomIdGenerator();
        let mapObj = {map};
        this.maps[id] = mapObj;

        if (!this.activeMapId)
            this.initMap(id, mapObj);

        if (!this.locateInterval)
            this.init();
        return id;
    };

    unsubscribe = (id) => {
        let wasActive = id === this.activeMapId;
        if (wasActive)
            this.tearDownMap();
        delete this.maps[id];
        if (this.getMaps().length === 0)
            this.stop();
        else if (wasActive)
            this.getMap();
    };

    init = () => {
        this.locate();
        this.locateInterval = setInterval(this.locate, 1000);
    };

    stop = () => {
        clearInterval(this.locateInterval);
        this.locateInterval = undefined;
    };

    getMap = () => {
        let mapObj = this.maps[this.activeMapId];
        if (!mapObj) {
            let keys = Object.keys(this.maps);
            let id = keys[0];
            this.initMap(id);
            this.activeMapId = id;
            mapObj = this.maps[id];
        }
        return mapObj;
    };

    initMap = (mapId, mapObj) => {
        this.activeMapId = mapId;
        if (!mapObj) mapObj = this.maps[mapId];
        mapObj.map.on('locationfound', this.onLocationFound);
        mapObj.map.on('locationerror', this.onLocationError);

    };

    tearDownMap = () => {
        let mapObj = this.maps[this.activeMapId];
        mapObj.map.off('locationfound', this.onLocationFound);
        mapObj.map.off('locationerror', this.onLocationError);
    };

    getMaps = () => {
        return Object.values(this.maps);
    };

    locate = () => {
        if (!this.locating) {
            this.locating = true;
            this.getMap().map.locate();
        }
    };

    onLocationError = e => {
        console.log(e.message);
        this.locating = false;
    };

    onLocationFound = e => {
        const maps = this.getMaps();
        maps.forEach(map => this.drawGpsMarker(map, e.latlng, e.accuracy));
        this.locating = false;
    };

    drawGpsMarker = (mapObj, latlng, accuracy) => {
        let map = mapObj.map;
        let marker = mapObj.marker;
        let circle = mapObj.circle;
        if (marker){
            marker.setLatLng(latlng)
        } else {
            mapObj.marker = L.marker(latlng, {
                icon: this.getGpsIcon()
            }).addTo(map);
            mapObj.marker.setZIndexOffset(20);
        }
        const radius = !!accuracy ? accuracy / 2 : 100;
        if (circle){
            map.removeLayer(circle);
        }
        mapObj.circle = this.getAccuracyCircle(latlng, radius).addTo(map);
    };


    getGpsIcon = () => {
        return L.icon({
            iconUrl: gpsMarker,
            iconSize: [10, 10],
            iconAnchor: [5, 5],
        });
    };

    getAccuracyCircle = (latlng, radius) => {
        return L.circle(latlng, {
            stroke: false,
            fillColor: '#0057ff',
            fillOpacity: 0.1,
            radius: radius
        });
    };
}



