export function getConnections(from, to, transfers = 0, numberOfOptions, walkSpeed) {
    const getQuery = () => `{
      trip(
        from: {
        ${from}
        }, 
        to: {
          ${to}
        },
        walkSpeed: ${walkSpeed},
        numTripPatterns: ${numberOfOptions},
        
        modes: {
          directMode: foot
          accessMode: foot
          egressMode: foot
          transportModes: [
            { transportMode: rail },
            { transportMode: bus },
            { transportMode: tram },
            { 
                transportMode: water,
                transportSubModes: [localPassengerFerry]
            },
          ]
        },
        
        maximumTransfers: ${transfers},
      ) {
        tripPatterns {
          startTime
          endTime
          duration
          legs {
            fromPlace {
              name
              quay {
                id
                stopPlace {
                  id
                }
                  name
                  publicCode
              }
            }
            toPlace {
              name
              quay {
                  name
                  publicCode
              }
            } 
          aimedStartTime
          aimedEndTime
            mode
            distance
            line {
              id
              publicCode
            }
            fromEstimatedCall {
              aimedDepartureTime
              expectedDepartureTime
              quay {
                id
                name
              }
            }
            toEstimatedCall {
              aimedDepartureTime
              expectedDepartureTime
            }
            stopsPassed: intermediateEstimatedCalls {
              quay {
                name
                longitude
                latitude
              }
            }
            pointsOnLink {
              points
              length
            }
          }
        }
      }
    }
    `;

    return new Promise((resolve, reject) => {
        // Shamash: https://api.entur.io/graphql-explorer/journey-planner-v3
        // console.log(getQuery(from, to, transfers, numberOfOptions));

        const query = getQuery(from, to, transfers, numberOfOptions);

        // console.log('query');
        // console.log(query);

        fetch('https://api.entur.io/journey-planner/v3/graphql',
            {
                method: 'POST',
                headers: {
                    'content-Type': 'application/json',
                    'et-client-name': 'Snurpeldorf'
                },
                body: JSON.stringify({query})
            }
        ).then(result => {
            result.json().then(json => {
                    if (json.data &&
                        json.data.trip &&
                        json.data.trip.tripPatterns
                    ) {
                        resolve(json.data.trip.tripPatterns);
                    } else {
                        reject('No data in response.');
                    }
                },
                error => {
                    console.log(error);
                    reject(error);
                })
        })
    });
}

export function findLocationOptions(searchString) {
    return new Promise((resolve, reject) => {
        fetch(`https://api.entur.io/geocoder/v1/autocomplete?text=${searchString}`
        ).then(result => {
            result.json().then(json => {
                    if (json.features
                    ) {
                        resolve(json.features);
                    } else {
                        reject('No features in response.');
                    }
                },
                error => {
                    console.log(error);
                    reject(error);
                })
        })
    });
}