import React, {useCallback, useState} from 'react';
import './Map.css';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import basicMapMarker from '../../../resources/basic-map-marker.svg'
import {expandBounds, getPathExpandedBounds, randomIdGenerator} from "../../../utils/utils";
import {getMapIcon} from "../../Icon/Icon";
import useEffectPartialDeps from "../../../utils/UseEffectPartialDeps";
import {useRecoilState} from "recoil";
import {gpsOnMapState} from "../../States";
import useEffectOnce from "../../../utils/UseEffectOnce";

let  markers, highlightedIndex;
const Map = props => {
    const [gpsOnMap] = useRecoilState(gpsOnMapState);
    const [map, setMap] = useState();
    const [locatorId, setLocatorId] = useState();
    const [id] = useState(randomIdGenerator());
    const {locator} = props;

    useEffectPartialDeps(() => {
        if (!map) return;
        if (!locator) {
            return;
        }
        if (gpsOnMap)
            subscribeToLocator();
        else
            unsubscribeFromLocator();
    }, [map, locator, gpsOnMap])

    useEffectOnce(() => {
        const onMapLongPress = e => {
            if (!props.searchGpsToLatLng) return;
            const {lng, lat} = e.latlng;
            props.searchGpsToLatLng({lng, lat});
        }

        const styles = {
            streets: 'mapbox/streets-v11',
            outdoors: 'mapbox/outdoors-v11',
            light: 'mapbox/light-v10',
            dark: 'mapbox/dark-v10',
            satellite:  'mapbox/satellite-streets-v11',
        };


        const mapId = styles[props.mapStyle] || 'mapbox/streets-v11';

        const tiles = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
            tileSize: 512,
            maxZoom: 18,
            zoomOffset: -1,
            id: mapId,
            accessToken: 'pk.eyJ1Ijoic2dyZXZsaW5nIiwiYSI6ImNqa3NtYjdzMTAwZnkzcWt0aWlsdnJsNGYifQ.F4FppnAyV_M9D4mu1fim-g'
        });

        if (props.markers)
            markers = props.markers.map((marker, i) => {
                const icon = getIcon(marker.iconType, props.selectedIndex === i);
                return L.marker(marker.coords, {
                    icon,
                    iconType: marker.iconType
                })
                    .on('click', marker.onClick)
                    // .on('contextmenu', marker.onLongPress);
            });

        let additionalMapAttributes = {
            // maxBounds: props.bounds,
            // center: [60.35955219331396, 5.341407358646394],
            // minZoom: 2,
            // maxZoom: 18,
            // zoom: 17,
        };

        // const center = props.miniMapCenter || [5.341407358646394, 60.35955219331396]
        const center = props.miniMapCenter || [7.9, 60.9]
            // [60.90092890248711, 7.932279109954835]
        if (!markers)
            additionalMapAttributes = {
                center,
                minZoom: 2,
                maxZoom: 18,
                zoom: 13,
            };

        if (props.miniMapCenter){
            let thing = L.latLng(...[...center].reverse()),
                bounds = L.latLngBounds(thing, thing);
            additionalMapAttributes.maxBounds = bounds;
            // additionalMapAttributes.maxBounds = L.bounds([center, center])
            additionalMapAttributes.maxBoundsViscosity = .9;
        }

        const localMap = L.map(document.querySelector(`#${id}`), {
            zoomControl: false,
            layers: [tiles],
            attributionControl: false,
            ...additionalMapAttributes
        });

        if (props.miniMapCenter){
            const miniMarker = L.marker({
                lat: center[1],
                lng: center[0]
        }, {
                icon: L.icon({
                    iconUrl: basicMapMarker,
                    iconSize: [50, 50],
                    iconAnchor: [25, 50],
                })
            });
            miniMarker.addTo(localMap);
        }

        if (markers && markers.length > 0) {
            let markerGroup = L.featureGroup(markers);
            localMap.addLayer(markerGroup);
            let bounds = expandBounds(markerGroup.getBounds(), 0.01);
            localMap.fitBounds(bounds);
        } else {
            localMap.setView(new L.LatLng(...[...center].reverse()), props.zoomLevel || 6);
        }

        if (props.path) {
            L.marker(props.path[props.path.length - 1], {
                icon: L.icon({
                    iconUrl: basicMapMarker,
                    iconSize: [20, 20],
                    iconAnchor: [10, 20],
                })
            }).addTo(localMap);

            L.polyline(props.path, {
                color: 'red'
            }).addTo(localMap);

            // expand bounds to make sure the entire path starts in view
            let expanded = getPathExpandedBounds(props.path, 0.0001),
                bounds = L.latLngBounds(
                    L.latLng(...expanded[0]),
                    L.latLng(...expanded[1])
                );

            localMap.fitBounds(bounds);
        }

        if (props.stopsPassed && props.stopsPassed.length > 0) {
            props.stopsPassed.forEach(stop =>
                L.circle(stop, {
                    color: 'dodgerblue',
                    fillColor: 'dodgerblue',
                    fillOpacity: 0.5,
                    radius: 5
                }).addTo(localMap)
            )
        }

        localMap.on('contextmenu', onMapLongPress);

        localMap.on('click', (p) => {
            if (props.onMapClick)
                props.onMapClick(p.latlng)
            else
                console.log([p.latlng.lat, p.latlng.lng]);
        });

        // handle markers:
        props.passMap && props.passMap(localMap);
        setMap(localMap);
        return unsubscribeFromLocator;
    });

    useEffectPartialDeps(() => {
        if (props.selectedIndex === highlightedIndex) return;
        if (highlightedIndex !== null) {
            const marker = markers[highlightedIndex];
            marker && marker.setIcon(getIcon(marker.options.iconType, false))
            highlightedIndex = null;
        }
        if (props.selectedIndex !== null) {
            const marker = markers[props.selectedIndex];
            marker && marker.setIcon(getIcon(marker.options.iconType, true))
            highlightedIndex = props.selectedIndex;
        }
    }, [props.selectedIndex])

    const subscribeToLocator = () => {
        if (locatorId) {
            return;
        }
        setLocatorId(locator.subscribe(map));
    };

    const unsubscribeFromLocator = useCallback(() => {
        setLocatorId(pv => {
            if (pv) locator.unsubscribe(pv);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locatorId]);

    const getIcon = (iconType, active) => {
        let iconUrl = 'data:image/svg+xml;base64,' + btoa(getMapIcon(iconType, active));
        return L.icon({
            iconUrl: iconUrl,
            iconSize: [50, 50],
            iconAnchor: [25, 25],
        });
    };

    return (
        <div
            id={id}
            className={'mapDiv'}
            onTouchStart={props.onMapFocus}
        />
    )
};

export default Map;