import React, {useState} from 'react';
import './Navigation.css'
import {Icon} from "../Icon/Icon";
import {useLocation, useNavigate} from "react-router-dom";
import Node from "../Nodes/Node";
import useEffectOnce from "../../utils/UseEffectOnce";
import {NavButtonBackground} from "./NavButtonBackground";
import {useRecoilState, useSetRecoilState} from "recoil";
import {draggingNodeState, editingNodeState, fromNodeState, gpsState, searchState} from "../States";
import {uuidv4} from "../../utils/utils";
import {CentralButton} from "../CentralButton/CentralButton";

let navRef = React.createRef();

export const Navigation = () => {
    const [screenDims, setScreenDims] = useState();
    const [editingNode, setEditingNode] = useRecoilState(editingNodeState);
    const [draggingNode] = useRecoilState(draggingNodeState);
    const [fromNode, setFromNode] = useRecoilState(fromNodeState);
    const setSearch = useSetRecoilState(searchState);
    const [gpsMode] = useRecoilState(gpsState);

    const navigate = useNavigate();
    const goResults = () => navigate('/results');

    const handleResize = () => {
        if (!navRef || !navRef.current) return;
        const rect = navRef.current.getBoundingClientRect();
        setScreenDims([rect.height, rect.width])
    }

    useEffectOnce(() => {
        window.addEventListener('resize', handleResize)
        handleResize();
        return () =>
            window.removeEventListener('resize', handleResize)
    })
    const pathname = useLocation().pathname;

    const renderLink = (key, notchSide) => {
        const path = `/${key}`;
        return (
            <div
                key={key}
                className={`navButton ${notchSide || ''} ${pathname === path ? 'active' : ''}`}
                // to={`/${key}`}
            >
                <NavButtonBackground
                    notchSide={notchSide}
                    width={screenDims[1] / 4}
                    onClick={() => navigate(path)}
                />
                <Icon type={key}/>
            </div>
        );
    }

    const renderLinks = () => {
        return [
            renderLink('settings'),
            renderLink('results', 'right'),
            renderLink('blobs', 'left'),
            renderLink('map'),
        ]
    }

    const renderNewNode = () =>
                screenDims ? (
                    <Node
                        hidden={draggingNode}
                        containerRef={navRef}
                        node={{
                            anchor: [screenDims[0], screenDims[1] / 2],
                            iconType: 'plus',
                            small: true
                        }}
                        onClick={() => {
                            console.log('new node clicked');
                        }}
                        setAnchor={(anchor, invalidPos) => {
                            if (editingNode || invalidPos) return;
                            setEditingNode({
                                iconType: 'pyramid',
                                label: '',
                                id: uuidv4(),
                                anchor,
                                // name: "Foo",
                                small: false
                            })
                        }}
                        new
                    />
                ) : (<></>)

    const renderGpsCentralButton = () => (
        <CentralButton
            className={`${
                fromNode && fromNode.gps ? ' selected' : ''
            }${
                draggingNode ? ' hidden' : ''
            }`}
            onClick={() => {
                if (!fromNode) {
                    setFromNode({gps: true})
                    return;
                }
                if(fromNode.gps) {
                    setFromNode(null)
                    return;
                }
                setSearch([fromNode, {gps: true}]);
                setFromNode(null);
                goResults();
                // setSearch([fromNode, {gps: true}])
            }
            }
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                // className="icon"
                viewBox="0 0 24 24"
                // height={props.size || "24"}
                // width={props.size || "24"}
                fill="white"
            >
                <path d="M12 11c1.33 0 4 .67 4 2v.16c-.97 1.12-2.4 1.84-4 1.84s-3.03-.72-4-1.84V13c0-1.33 2.67-2 4-2zm0-1c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6 .2C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z"/>
            </svg>
        </CentralButton>
    )

    const renderCentralThing = () => {
        if (!['/blobs', '/map'].includes(pathname)) return (<></>)
        if (gpsMode === 'centralButton')
            return renderGpsCentralButton()
        return renderNewNode()
    }

    return (
        <>
            <div
                className="navWrapper"
                ref={navRef}
            />
            {renderCentralThing()}
            {screenDims ? (
                <div className={`navigation ${draggingNode ? 'hidden' : ''}`}>
                    <div className="main">
                        {renderLinks()}
                    </div>
                </div>
            ) : (<></>)
            }
        </>
    );
};