import React, {useState} from 'react';
import './Blobs.css';
import {
    deletingNodeState,
    draggingNodeState,
    editingNodeState,
    fromNodeState,
    nodesState,
    searchState,
    gpsState
} from "../../States";
import {useRecoilState, useSetRecoilState} from "recoil";
import Node from "../../Nodes/Node";
import {useNavigate} from "react-router-dom";
import useEffectPartialDeps from "../../../utils/UseEffectPartialDeps";
import {HelpScreen} from "../../HelpScreen/HelpScreen";
import {DeleteOrEditNodeUi} from "../../Navigation/DeleteOrEditNodeUi";

let floatyRef = React.createRef();
export const Blobs = () => {
    const [locations, setLocations] = useRecoilState(nodesState);
    const [selectedNode] = useRecoilState(fromNodeState);

    const [gpsMode] = useRecoilState(gpsState);
    const [fromNode, setFromNode] = useRecoilState(fromNodeState);
    const setSearch = useSetRecoilState(searchState);
    const setEditingNode = useSetRecoilState(editingNodeState);
    const setDeletingNode = useSetRecoilState(deletingNodeState);
    const [draggingNode, setDraggingNode] = useRecoilState(draggingNodeState);
    const [clickedNode, setClickedNode] = useState();
    const [hoveredSection, setHoveredSection] = useState(null);

    const navigate = useNavigate();
    const goResults = () => navigate('/results');

    useEffectPartialDeps(() => {
        if (clickedNode) onNodeClick(clickedNode);
        setClickedNode(null);
    }, [clickedNode])

    const onNodeClick = (node) => {
        if (gpsMode === 'startFromGps') {
            setSearch([{gps: true}, node]);
            goResults();
            return;
        }
        if (!fromNode) {
            setFromNode(node);
        } else if (fromNode.id === node.id)
            setFromNode(null);
        else {
            setSearch([fromNode, node]);
            setFromNode(null);
            goResults();
        }
    }



    const updateLocation = (location) =>
        setLocations({
            ...locations,
            [location.id]: location
        })

    const renderBlob = node => (
        <Node
            key={node.id}
            selected={selectedNode && node.id === selectedNode.id}
            containerRef={floatyRef}
            node={node}
            onClick={() => setClickedNode(node)}
            setAnchor={anchor => {
                updateLocation({
                    ...node,
                    anchor
                })
            }}
            onDragStart={() => {
                window.navigator && window.navigator.vibrate && window.navigator.vibrate([10]);
                setDraggingNode(true);
            }}
            onDragEnd={(section) => {
                setDraggingNode(false);
                if (section === 'delete')
                    setDeletingNode(node)
                else if (section === 'edit')
                    setEditingNode(node);
            }}
            updateSection={setHoveredSection}
        />
    );

    return locations ? (
        <div
            className="blobs screen"
            ref={floatyRef}
        >
            <DeleteOrEditNodeUi
                draggingNode={draggingNode}
                hoveredSection={hoveredSection}
            />
            <HelpScreen locations={Object.values(locations)} loc={'blobs'}/>
            {Object.values(locations).map(renderBlob)}
        </div>
    ) : (
        <p>
            No locations
        </p>
    );
}