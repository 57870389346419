import ReactGA from "react-ga4";

export const initGa = () => {
    // const TRACKING_ID = "G-VVNMN98WYY"; // Journey search propertyen som kom ved siden av snurpeldorf
    const TRACKING_ID = "G-9EFJ1CSKCM";
    ReactGA.initialize(TRACKING_ID);
}

export const useAnalyticsEventTracker =
    (action = "test action") =>
        (label = "test label") => {
            ReactGA.event({category: 'snurpeldorf', action, label});
        };