import React, {useEffect} from 'react';
import {uuidv4} from "../../utils/utils";

let id;
export const TextInput = props => {
    useEffect(() => {
        id = uuidv4();
    }, []);
    return (
        <div className={`textInput ${props.className || ''}`}>
            {
                props.label && (
                    <label
                        htmlFor={`#${id}`}
                        className={'textInput__label'}
                    >
                        {props.label}
                    </label>
                )
            }
            <input
                id={id}
                type="text"
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                placeholder={props.placeholder}
                onFocus={props.onFocus}
            />
        </div>
    )
};